import React from 'react';
import "../../../css/AreYouDoc.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext.js';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import AreYouDocView from './AreYouDocView.jsx';
import { Toaster, toast } from 'sonner'

const AreYouDoc = () => {
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const { langStrings } = useLanguage();
  const ST = langStrings.AreYouDoc;
  const navigate = useNavigate();
  const location = useLocation();
  const gotohome = () => {
    const redirectTo = location.state?.from?.pathname || '/'; 
    navigate(redirectTo);
  };

  const takeToDocRegister = () => {
    if(isAuthenticated() && authUser()?.isUser && !authUser()?.isDoctor){
      navigate('/new-doctor-register');
    }else if(!authUser()?.isUser){
      toast.warning(ST.HEADERS.login, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      navigate('/user-login', { state: { from: 'List Your Practice' } });
    }else if(authUser()?.isDoctor){
      toast.info(ST.HEADERS.doctor, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
    });
    }
  }

  return (
    <AreYouDocView 
      ST={ST}
      gotohome={gotohome}
      takeToDocRegister={takeToDocRegister}
    />
  );
};

export default AreYouDoc;
