import React from 'react'
import Footer from './Footer';
import NavBarList from '../../docsearch/components/NavBarList'
import { useLanguage } from './LanguageContext.js';
import { useOnlineStatus } from '../../../common_components/hooks/OnlineStatus.js';
import OfflineStatus from '../../../common_components/Offline/OfflineStatus.jsx';

const Careers = () => {
    const { language,langStrings } = useLanguage();
    const ST = langStrings.CAREERSPAGE;
    const isOnline = useOnlineStatus();
    if(!isOnline) {
      return <OfflineStatus />
    }
    return (
      <div className='bg-light' style={{ display: 'flex', flexDirection: 'column', }}>
      <NavBarList />
      <div className='container-fluid mt-4 mb-5'>

      
      <div className="d-flex justify-content-center align-items-center flex-grow-1 my-4  bg-light">
        <div className="container p-5 rounded shadow bg-white text-dark" style={{ width: '80%', height: 'auto',marginTop:'10vh'}}>
          <h1 className="text-center mb-5">{ST.label}</h1>


          <p className='fw-bold mb-2'>{ST.label1}</p>
          <p className='mb-2'>
          {ST.label2}
          </p>
          <p className='mb-4'>
          {ST.label3}
          </p>

          <p className='fw-bold mb-3'>
          {ST.label4}
          </p>
          <ul>
            <li><span className='fw-bold '>{ST.label5}</span> <span>{ST.label6}</span></li>
            <li><span className='fw-bold '>{ST.label7}</span> <span>{ST.label8}</span></li>
            <li><span className='fw-bold '>{ST.label9}</span> <span>{ST.label10}</span></li>
            <li><span className='fw-bold mb-5'>{ST.label11}</span> <span>{ST.label12}</span></li>
          </ul>  
          <p className='fw-bold mb-2'>
          {ST.label13}
          </p>
          <p className='mb-4'>
          {ST.label14}
          </p>
          <p className='fw-bold mb-2'>
          {ST.label15}
          </p>
          <p className='mb-4'>
          {ST.label16}
          </p>
          <p>
          {ST.label17}
          </p>
        </div>
        </div>
      </div>
      <Footer/>
      </div>
    )
}

export default Careers