import React, { useEffect, useRef, useState } from 'react';
import './ImageCard.css'; 
import { ImLocation2 } from "react-icons/im";
import { FaEuroSign } from "react-icons/fa";
import { BsGlobe2 } from "react-icons/bs";
import { MdPhoneInTalk } from "react-icons/md";
import { SlSpeech } from "react-icons/sl";
import { FaCalendarAlt } from "react-icons/fa";
import { useLanguage } from '../../homepage/components/LanguageContext';
import { Button, Modal } from 'react-bootstrap';
import { Form,Row,Col } from 'react-bootstrap';
import { IoMdStar } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { GiTwirlyFlower } from "react-icons/gi";
import { toast } from 'sonner';
import DoctorProfileImage from './DoctorProfileImage';

var _ = require('lodash');

const allDays = [0,1, 2, 3, 4, 5];

const convertToTime = (minutes) => {
  if (minutes === null) return null;
  const hours = Math.floor(minutes / 60);
  let mins = minutes % 60;
  mins = mins < 10 ? '0' + mins : mins;
  return `${hours}:${mins}`;
};

const ImageCard = ({ user,
  onHeightChange,
  id,
  isdoc,
  searchTextTop,
  getDocCountMatchingStr,
  count,
  users,
  unsetAllDocs,
  setSelectedDoc,
  getDocLocAddressStr1,
  getLanguageString,
  selectedDoc,
  searchFormData,
  handleFormChange,
  handleFormSubmitNew,
  setSearchFormData,
  onHover,
  isHovered,
 }) => {

  const { language,langStrings } = useLanguage();
  const navigate  = useNavigate();
  const ST = langStrings.SEARCHFIL;
  const MT = langStrings.BOOKINGMODAL;
  const dayWiseSlots = user?.aptSlotsApi?.dayWiseSlots || [];
  const dateWiseSlots = user?.aptSlotsApi?.datewiseSlots || [];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const [UserId, setUserId] = useState(-1);

  const displayValue = (defaultKey, deKey) => {
    return language === 'English' ? defaultKey : deKey;
  };

  const getUserId = ()=>{
    if(isAuthenticated() ){
      setUserId(authUser().userDto.id);
    }
  }

  useEffect(() => {
    getUserId();
    
  }, [isAuthenticated,authUser])

  const cardRef = useRef(null);
  const [cardHeight, setCardHeight] = useState(0);

  useEffect(() => {
    if (cardRef.current) {
      const height = cardRef.current.offsetHeight;
      setCardHeight(height);
      onHeightChange(id,height); 
    }
  }, [user, onHeightChange]);

  const dayIs = (daySlot) =>{
    const [year, month, day] = daySlot.split('-');
    const dayNumber = parseInt(day, 10);
    return dayNumber;
  }

  const monthIs = (daySlot) =>{
    const [year, month, day] = daySlot.split('-');
    return month;
  }

  const docLocation = getDocLocAddressStr1(user?.centers[0]);
  const docLocationMap = {lat:user?.centers?.[0]?.lat || 47.80164,lng:user?.centers?.[0]?.lng||47.80164};
  const form = useRef();
  const profileImagePrefix = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;
  const [formData, setFormData] = useState({
    fullName: '',
    preferredDoctorId:user.id,
    preferredDoctorCenterId:user?.aptSlotsApi?.doctorCenterId || -1,
    preferredDoctorSpeciality:'',
    preferredClinicHospital:'',
    preferredTimeslot: '',
    preferredAvoidDays: '',
    isAptUrgent: '',
    insuranceType: '',
    insuranceNumber: '',
    specialMention: '',
    phone: '',
    email: '',
    terms_conditions: false,
    info:{

    },
    type:'Booking Request',
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.fullName) newErrors.fullName = MT.label26;
    if (!formData.preferredTimeslot) newErrors.preferredTimeslot = MT.label27;
    if (!formData.preferredAvoidDays) newErrors.preferredAvoidDays = MT.label28;
    if (!formData.isAptUrgent) newErrors.isAptUrgent = MT.label29;
    if (!formData.insuranceType) newErrors.insuranceType = MT.label30;
    if (formData.insuranceType && !formData.insuranceNumber) newErrors.insuranceNumber = MT.label31;
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = MT.label32;
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = MT.label33;
    if (!formData.terms_conditions) newErrors.terms_conditions = MT.label34;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit1 = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(e);
      handleClose();
    }else{
      alert('Please Fill all the Fields Correctly');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
    const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+'/lead-capture', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-LOCALE': `${LanguageLocale}`,
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      toast.success(ST.IMAGECARD.label13, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      console.log('Form submitted successfully');
    } else {
      toast.warning(ST.IMAGECARD.label14, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      console.log('Failed to submit form');
    }
  };

 
  function drawModernizationStars(ratingCount){
    let times = Number(ratingCount);

    if(isNaN(times))
      return "NA";

    if(!isNaN(times)){
      if (times == 0)
        return "NA";
      else if (times == 1)
        return <GiTwirlyFlower style={{marginRight:'5px'}}/>
      else if (times == 2)
        return <><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/></>
      else if (times == 3)
        return <><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/></>
      // else if (times == 4)
      //   return <><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/></>
      // else if (times == 5)
      //   return <><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/><GiTwirlyFlower style={{marginRight:'5px'}}/></>
    }
  }

  const hoverTimeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      onHover(user.id);
    }, 100); // Small delay to prevent jittery movement
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    onHover(null);
  };

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);
  
  return (
    <div 
    ref={cardRef} 
    class="container my-cards"  
    onMouseEnter={handleMouseEnter}
    // onMouseLeave={handleMouseLeave}
    style={{
     
      transition: 'background-color 0.2s ease'
    }}>
      <div style={{width:'30%',cursor:'pointer'}} onClick={() =>{  navigate(`/doctor/${user.completeUrlPath}`, { state: { user,docLocation,docLocationMap,isdoc,UserId} })}}>
        <div 
        className='pfp-cont'
        >
          <DoctorProfileImage imageUrl={user?.prefixedProfileImage} borderRadius={999} alt=""/>
          <div className='title-div' >
            <p className='prof-p-text' >
              {user?.profileName}
              {/* {user.title + ' ' + user.firstname + ' ' + user.lastname} */}
            </p>
            <p className='prof-p'>
             {/* {getLanguageString(user.professionList[0],user.professionListDe[0],language)} */}
             {language === 'English' ? user?.specialistList.join(', ') : user?.gender === 'FEMALE' ? user?.specialistListFemaleDe.join(',') : user?.specialistListMaleDe.join(', ')}
             {/* {getLanguageString(_.get(language,user,'specialistList[0]') , user.gender === 'MALE' ? _.get(user,'specialistListMaleDe[0]'): _.get(user,'specialistListFemaleDe[0]'))} */}
              {/* {user.professionList == 'Doctor' ?  user.specialityList[0]:user.professionList[0]} */}
            </p>
          </div>
        </div>
        <div>
          <div className='left-cont' >
            <ImLocation2 style={{marginRight:'8px',width:"20px",height:"20px",minHeight:'20px',minWidth:'20px',marginTop:'4px'}} />
            {user.centers && user.centers.length > 0 ? <span  style={{
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  whiteSpace: 'normal',
  overflowWrap: 'break-word'
}} class="my-0 font-weight-normal">  {getDocLocAddressStr1(user.centers[0])} </span> : <span>No Center Available</span>}
          </div>
          <div className='left-cont' >
            <FaEuroSign style={{marginRight:'8px',width:"20px",height:"20px",minHeight:'20px',minWidth:'20px',marginTop:'3px'}} />
            <span  style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              overflowWrap: 'break-word'
            }}>{user?.insuranceCompanyList.join(', ')}</span>
          </div>
          <div className='left-cont'>
            <BsGlobe2  style={{marginRight:'8px',width:"20px",height:"20px",minHeight:'20px',minWidth:'20px',marginTop:'4px'}} />
            <span  style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              overflowWrap: 'break-word'
            }}>{user?.centers[0]?.website}</span>
          </div>
          <div className='left-cont'>
            <SlSpeech style={{marginRight:'8px',width:"20px",height:"20px",minHeight:'20px',minWidth:'20px',marginTop:'4px'}} />
            {user.centers && user.centers.length ? <span  style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              overflowWrap: 'break-word'
            }} class="my-0 font-weight-normal">  {user.centers[0].mail } </span> : <span></span>}
          </div>
          <div className='left-cont' >
            <MdPhoneInTalk style={{marginRight:'8px',width:"20px",height:"20px",minHeight:'20px',minWidth:'20px',marginTop:'4px'}} />
            {user.centers && user.centers.length ? <span  style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              overflowWrap: 'break-word'
            }} class="my-0 font-weight-normal">  {user.centers[0].phone} </span> : <span></span>}
          </div>
        </div>
      </div>


      <div style={{width:'30%'}} >
        <div style={{display:'flex',height:'50%'}}>
          <div className='bhenChod' >
            <div className='bhenChod-header'>
            {user.info == null || Object.keys(user.info).length === 0 ? 'NA' : language == 'English'? user.info['1_a_n_p'] : user.info['1_a_n_p'] == 'Yes' ? 'Ja' : 'Nein'}
            </div>
            <div className='bhenChod-footer'>
            {ST.IMAGECARD.label}
            </div>
          </div>
          <div className='bhenChod' >
            <div className='bhenChod-header'>
            {user.info == null || Object.keys(user.info).length === 0 ?'NA':user.info['2_a_w_t'] + ST.IMAGECARD.label12} 
            </div>
            <div className='bhenChod-footer'>
            {ST.IMAGECARD.label1}
            </div>
          </div>
        </div>

        <div style={{display:'flex',height:'50%'}}>
          <div style={{cursor:'pointer'}} className='bhenChod' onClick={handleShow1}>
            <div className='bhenChod-header' style={{ display: 'flex', alignItems: 'center' }}>
              {drawModernizationStars( user.info == null || Object.keys(user.info).length === 0 ?'NA':user.info['3_p_m_i'] ) }
              
              {/*<GiTwirlyFlower style={{marginRight:'5px'}}/> */}
            </div>
            <div className='bhenChod-footer'>
            {ST.IMAGECARD.label2}
            </div>
          </div>
          <div className='bhenChod'>
            <div className='bhenChod-header' style={{ display: 'flex', alignItems: 'center' }}>
              <IoMdStar className='star-logo' />
              <p className='star-span mt-1' >{user?.ratingGoogle}</p>
            </div>
            <div className='bhenChod-footer'>
              {ST.IMAGECARD.label3}
            </div>
          </div>
        </div>
      </div>
      

      <div style={{width:'40%',marginTop:'8px',marginBottom:'2%'}} >
          <div onClick={handleShow} style={{height:'15%',backgroundColor:'#f35b2a',fontWeight:'bold',color:'white',display:'flex', justifyContent:'center',alignItems:'center',fontSize:'25px',borderRadius: '20px',cursor:'pointer',border:'solid'}}>
            <FaCalendarAlt style={{marginRight:'20px'}} />
            <span className={`${language === 'German' ? 'Bookings-text' : 'Bookings-text-eng'}`}>{ST.IMAGECARD.label10}</span>
          </div>
          <div style={{height:'85%',marginTop:'5px', border:'1px solid #a0a0a0', borderRadius:'20px',overflow:'clip',boxSizing: 'border-box'}}>
            <div style={{height:'15%',backgroundColor:'#a0a0a0',color:'white',fontWeight:'bold',fontSize:'25px',justifyContent:'center',alignItems:'center',display:'flex', borderRadius:'2px 2px 0px 0px'}}>
              <span className='Bookings-text1'>{ST.IMAGECARD.label11}</span>
            </div>
          <div >
            {
              allDays.map((day,index)=>{
                const slot = dayWiseSlots.find(slot => slot.dow === index+1); 
                const beforeLunch = slot && slot.beforeLunchSt ? `${convertToTime(slot.beforeLunchSt)} - ${convertToTime(slot.beforeLunchEn)}` : '00:00 - 00:00';
                const afterLunch = slot && slot.afterLunchSt ? `${convertToTime(slot.afterLunchSt)} - ${convertToTime(slot.afterLunchEn)}` : '00:00 - 00:00';
                return(
                <div className='timings' key={index} >
                  <div className='Bookings-label  ps-2' >
                    {ST.IMAGECARD[`label${index+4}`]}
                  </div>
                  { beforeLunch != '00:00 - 00:00' ?
                    <button className='Bookings-btn mr-1' >
                      {beforeLunch}
                    </button>:
                    <button className='Bookings-btn1 ' >
                      {beforeLunch}
                    </button>
                  }
                  
                  {afterLunch != '00:00 - 00:00'?
                    <button className='Bookings-btn mr-1' >
                      {afterLunch}
                    </button>:
                    <button className='Bookings-btn1 ' >
                      {afterLunch}
                    </button>
                  }
                </div>);
              })
            }
          </div>
      </div>
    </div>
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{MT.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-dark text-white">
          <div style={{ backgroundColor: '#f35b2a' }} className="p-4">
            <h5 style={{ textAlign: 'justify', textJustify: 'inter-word' }} className="mb-4">
              {MT.label1}
            </h5>
            <p style={{ textAlign: 'justify', textJustify: 'inter-word' }} className="text-white h5 font-weight-bold mb-4">
              {MT.label2}
            </p>
          </div>
          <div className="p-4">
            <Form onSubmit={handleSubmit1}>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formName">
                    <Form.Label style={{ color: 'white' }}>{MT.label3}</Form.Label>
                    <Form.Control
                      type="text"
                      name="fullName"
                      placeholder={MT.label24}
                      value={formData.fullName}
                      onChange={handleInputChange}
                    />
                    {errors.fullName && <div className="text-danger">{errors.fullName}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Label style={{ color: 'white' }}>{MT.label4}</Form.Label>
                  <div className="d-flex justify-content-around">
                    <Form.Check
                      type="radio"
                      label={MT.label14}
                      name="preferredTimeslot"
                      value="Morning"
                      onChange={handleInputChange}
                      checked={formData.preferredTimeslot === 'Morning'}
                    />
                    <Form.Check
                      type="radio"
                      label={MT.label15}
                      name="preferredTimeslot"
                      value="Afternoon"
                      onChange={handleInputChange}
                      checked={formData.preferredTimeslot === 'Afternoon'}
                    />
                    <Form.Check
                      type="radio"
                      label={MT.label16}
                      name="preferredTimeslot"
                      value="Evening"
                      onChange={handleInputChange}
                      checked={formData.preferredTimeslot === 'Evening'}
                    />
                  </div>
                  {errors.preferredTimeslot && <div className="text-danger">{errors.preferredTimeslot}</div>}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formSpecificTimes">
                    <Form.Label style={{ color: 'white' }}>{MT.label5}</Form.Label>
                    <Form.Control
                      type="text"
                      name="preferredAvoidDays"
                      placeholder={MT.label25}
                      value={formData.preferredAvoidDays}
                      onChange={handleInputChange}
                    />
                    {errors.preferredAvoidDays && <div className="text-danger">{errors.preferredAvoidDays}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Label style={{ color: 'white' }}>{MT.label6}</Form.Label>
                  <div className="d-flex justify-content-around">
                    <Form.Check
                      type="radio"
                      label={MT.label17}
                      name="isAptUrgent"
                      value="Yes"
                      onChange={handleInputChange}
                      checked={formData.isAptUrgent === 'Yes'}
                    />
                    <Form.Check
                      type="radio"
                      label={MT.label18}
                      name="isAptUrgent"
                      value="No, can wait a week or more"
                      onChange={handleInputChange}
                      checked={formData.isAptUrgent === 'No'}
                    />
                  </div>
                  {errors.isAptUrgent && <div className="text-danger">{errors.isAptUrgent}</div>}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Label style={{ color: 'white' }}>{MT.label7}</Form.Label>
                  <div className="d-flex justify-content-around">
                    <Form.Check
                      type="radio"
                      label={MT.label19}
                      name="insuranceType"
                      value="Public"
                      onChange={handleInputChange}
                      checked={formData.insuranceType === 'Public'}
                    />
                    <Form.Check
                      type="radio"
                      label={MT.label20}
                      name="insuranceType"
                      value="Private"
                      onChange={handleInputChange}
                      checked={formData.insuranceType === 'Private'}
                    />
                  </div>
                  {errors.insuranceType && <div className="text-danger">{errors.insuranceType}</div>}
                  <Form.Group controlId="formInsuranceNumber" className="mt-3">
                    <Form.Label style={{ color: 'white' }}>{MT.label8}</Form.Label>
                    <Form.Control
                      type="text"
                      name="insuranceNumber"
                      placeholder={MT.label21}
                      value={formData.insuranceNumber}
                      onChange={handleInputChange}
                    />
                    {errors.insuranceNumber && <div className="text-danger">{errors.insuranceNumber}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formNotes">
                    <Form.Label style={{ color: 'white' }}>{MT.label9}</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="specialMention"
                      rows={3}
                      value={formData.specialMention}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formPhone">
                    <Form.Label style={{ color: 'white' }}>{MT.label10}</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder={MT.label22}
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                    {errors.phone && <div className="text-danger">{errors.phone}</div>}
                  </Form.Group>
                  <Form.Group controlId="formEmail" className="mt-3">
                    <Form.Label style={{ color: 'white' }}>{MT.label11}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={MT.label23}
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <div className="text-danger">{errors.email}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="formTerms" className="mt-3">
                <Form.Check
                  type="checkbox"
                  name="terms_conditions"
                  label={MT.label12}
                  checked={formData.terms_conditions}
                  onChange={handleInputChange}
                />
                {errors.terms_conditions && <div className="text-danger">{errors.terms_conditions}</div>}
              </Form.Group>

              <Button style={{ backgroundColor: '#f35b2a' }} type="submit" className="w-100 mt-3">
                {MT.label13}
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>

<Modal show={show1} onHide={handleClose1} size="lg" centered>
        <Modal.Body>
          <div className="modal-content-structure">
            <div className="top-section">
              <div className="logo">
                <GiTwirlyFlower size={200}/>
              </div>
              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <h2 className="title">Arztme <span className="star-text">Star</span></h2>
               
                <p className="subtitle">{ST.STARMODAL.label}</p>
              </div>

            </div>

         
          <br />

            <div className="bottom-section">
              <div className="item">
              <GiTwirlyFlower size={75} />
              
                <p className='h3'>{ST.STARMODAL.label1}</p>
              </div>
              <div className="item">
              <GiTwirlyFlower size={75}/>
              <GiTwirlyFlower size={75}/>
                <p className='h3'>{ST.STARMODAL.label2}</p>
              </div>
              <div className="item">
              <GiTwirlyFlower size={75}/>
              <GiTwirlyFlower size={75}/>
              <GiTwirlyFlower size={75}/>
                <p className='h3'>{ST.STARMODAL.label3}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:'#f35b2a'}} onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  {/* <style jsx>{
    `
    
    
   
    `
  }
  </style> */}
    </div>
  );
};
export default ImageCard;


   
     
  
