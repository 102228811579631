import { useState, useEffect } from 'react';
import utils from './../../../utils.js';
import { useParams, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { getLanguageString } from './../../../utils.js';
import getDoctorsFromRelUrl from '../services/getSearch.js';
import DocSearchView from './DocSearchView.jsx';
import _ from 'lodash';
import { useLanguage } from '../../homepage/components/LanguageContext.js';

export default function DoctorSearch() {
  const { profession } = useParams();
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState({ doctorList: [], filterList: [] });
  const [selectedDoc, setSelectedDoc] = useState(0);
  const [selectType, setSelectedType] = useState('');
  const [isdoc, setIsdoc] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); 
  const [totalPages, setTotalPages] = useState(1); 
  const [searchkeyEn,setSearchKeyEn] = useState('');
  const [searchkeyDe,setSearchKeyDe] = useState('');
  const [locationkeyEn,setLocationKeyEn] = useState('');
  const [locationkeyDe,setLocationKeyDe] = useState('');
  const {language} = useLanguage();
  
  const sz = 10;

  const [searchFormData, setSearchFormData] = useState({
    searchkey: '',
    locationkey: '',
    counter: '',
    language: '',
    profession: '',
    speciality: '',
    acceptingNewPatient: '',
    insuranceGroup: '',
    isVerified: '',
    from: 0,
    size: sz, 
    currPage: 0,
  });

  const [searchTextTop, setSearchTextTop] = useState("Find doctors.");
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const profileImagePrefix = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;
  const queryParams = new URLSearchParams(location.search);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false)
   
  useEffect(() => {
    if (isAuthenticated()) {
      setIsdoc(authUser().userDto.id);
      setSearchTextTop("Welcome " + authUser().userDto.firstname + ". Start searching for top doctors near you");
    }
  }, []);


  useEffect(() => {
    let loadOnce = false;
    ["searchkey", "locationkey", "counter", "city", "language", "profession", "speciality", "acceptingNewPatient", "insuranceGroup", "isVerified", "from", "size","currPage"].forEach(function (key) {
      searchFormData[key] = queryParams.get(key);
      loadOnce = true;
    });
    if (loadOnce) {
      fetchDataFromFormAndCallApi(location.search, "LOCCHANGE");
    };

}, [location.search,searchFormData]);
useEffect(() => {
  window.scrollTo(0, 0);
}, [location]);

  function handleFormChange(event) {
    const { target: { name, value } } = event;
    setSearchFormData({ ...searchFormData, [name]: value });
  }

  async function handleFormSubmitNew(event) {
    event.preventDefault();
    fetchDataFromFormAndCallApi(0);
  }

  async function fetchDataFromFormAndCallApi() {
    setIsSkeletonLoading(true)
    const searchRelUrl = utils.getQueryString(searchFormData); 
    navigate(searchRelUrl);
    try {
      const resp = await getDoctorsFromRelUrl(searchRelUrl,language);
    if (resp.success ) {
      if(resp.respJson?.data){
        setUsers(resp.respJson.data);
      console.log(`sarthak bhai data is`,resp.respJson.data);
      setTotalPages(Math.ceil(resp.respJson.data.totalCount / sz));
      setSearchKeyEn(resp.respJson.data.searchkey);
      setSearchKeyDe(resp.respJson.data.searchkeyDe);
      setLocationKeyEn(resp.respJson.data.locationkey);
      setLocationKeyDe(resp.respJson.data.locationkeyDe);
      }
      
    }
    } catch (error) {
      console.error('Fetch error:', error);
    }finally{
      setIsSkeletonLoading(false)
    }
    
  }

  function getDocCountMatchingStr(docList) {
    if (!docList || docList.length === 0) return 'No Matches Found';
    else return docList.length + " Matches Found";
  }

  function unsetAllDocs() {
    users.doctorList.forEach((doc) => {
      doc.isSelected = false;
    });
  }

  function handleNextPage() {
    if (searchFormData.currPage  < totalPages - 1) {
      let newSearchFormData = _.merge(searchFormData , {from: Number(searchFormData.from)+sz, currPage: Number(searchFormData.currPage)+1 });
      setSearchFormData(newSearchFormData);
      fetchDataFromFormAndCallApi();
    }
  }

  function handlePrevPage() {
    if (searchFormData.currPage  > 0) {
      let newSearchFormData = _.merge(searchFormData , {from: Number(searchFormData.from)-sz, currPage: Number(searchFormData.currPage)-1 });
      setSearchFormData(newSearchFormData);
      fetchDataFromFormAndCallApi();
    }
  }

  function goToPage(pageNumber) {
    if (pageNumber < 0 || pageNumber > totalPages) return;
    let newSearchFormData = _.merge(searchFormData , {from: pageNumber*sz, currPage: pageNumber });
    setSearchFormData(newSearchFormData);
    fetchDataFromFormAndCallApi();
  }

  return (
    <DocSearchView
    isSkeletonLoading={isSkeletonLoading}
      searchTextTop={searchTextTop}
      isdoc={isdoc}
      getDocCountMatchingStr={getDocCountMatchingStr}
      profileImagePrefix={profileImagePrefix}
      count={count}
      users={users}
      unsetAllDocs={unsetAllDocs}
      setSelectedDoc={setSelectedDoc}
      getDocLocAddressStr1={utils.getDocLocAddressStr1}
      getLanguageString={utils.getLanguageString}
      getDocLocAddressStr={utils.getDocLocAddressStr}
      selectedDoc={selectedDoc}
      searchFormData={searchFormData}
      handleFormChange={handleFormChange}
      handleFormSubmitNew={handleFormSubmitNew}
      setSearchFormData={setSearchFormData}
      currentPage={currentPage}
      handlePrevPage={handlePrevPage} 
      handleNextPage={handleNextPage} 
      goToPage={goToPage} 
      totalPages={totalPages}
      searchkeyEn={searchkeyEn}
      searchkeyDe={searchkeyDe}
      locationkeyEn={locationkeyEn}
      locationkeyDe={locationkeyDe}
    />
  );
}
