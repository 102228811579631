import React from 'react';
import { useLanguage } from '../../features_new/homepage/components/LanguageContext';
import Footer from '../../features_new/homepage/components/Footer';

const NotFoundPage = () => {
  const {language, langStrings} = useLanguage();
  console.log(langStrings);
  const FT = langStrings.NOTFOUNDPAGE;
  
  return (
    <>
      <div style={styles.container}>
        <div style={styles.card}>
          <h1 style={styles.title}>{FT.Page_Not_Found}</h1>
          <p style={styles.message}>
          {FT.Url}
          </p>
          <button
            style={styles.button}
            onClick={() => window.location.href = '/'}
          >
            {FT.Home}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  card: {
    backgroundColor: '#f35b2a',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '40px',
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    color: 'white',
    marginBottom: '20px',
  },
  message: {
    fontSize: '1rem',
    color: 'white',
    marginBottom: '30px',
    lineHeight: '1.5',
  },
  button: {
    backgroundColor: 'white',
    color: '#f25b2a',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

export default NotFoundPage;