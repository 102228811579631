const germanStrings = {
  NEW_DOCTOR_REGISTER_PAGE:{
    label:'Allgemeine Informationen',
    label1:'Kontaktdaten',
    label2:'Ich akzeptiere das',
    label3:'Geschäftsbedingungen',
    label4:'Ihrer Website.',
    label5:'Registrieren',
  },
  FALLBACKERRORPAGE:{
    Oops_Something_Went_Wrong:'Hoppla! Etwas ist schief gelaufen',
    We_are_sorry:`Es tut uns leid, aber etwas Unerwartetes ist passiert. Bitte versuchen Sie es später noch einmal.`,
    Try_Again:'Versuchen Sie es erneut'
  },
  DOCTORREGISTER: {
    title: `Bitte wählen Sie einen Titel aus`,
    first: `Bitte geben Sie Ihren Vornamen ein`,
    last: `Bitte geben Sie Ihren Nachnamen ein`,
    profile: `Bitte geben Sie Ihren Profilnamen ein`,
    profession: `Bitte wählen Sie Ihren Beruf aus`,
    speciality: `Bitte wählen Sie eine Fachrichtung aus`,
    specialist: `Bitte wählen Sie einen Spezialisten aus`,
    language: `Bitte wählen Sie die gesprochenen Sprachen aus`,
    practice: `Bitte geben Sie Ihre Praxisgröße ein`,
    country: `Bitte wählen Sie Ihr Land aus`,
    state: `Bitte wählen Sie Ihr Bundesland aus`,
    city: `Bitte wählen Sie Ihre Stadt aus`,
    address: `Bitte geben Sie Ihre Adresse ein`,
    street: `Bitte geben Sie Ihre Straße ein`,
    area: `Bitte geben Sie Ihren Bereich ein`,
    pincode: `Bitte geben Sie Ihre Postleitzahl ein`,
    email: `Bitte geben Sie Ihre E-Mail-Adresse ein`,
    inValidEmail: `Bitte geben Sie eine gültige E-Mail-Adresse ein`,
    phone: `Bitte geben Sie Ihre Telefonnummer ein`,
    welcome:`Willkommen bei Arztme`,
    wrong:`Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal`,
},
  OFFLINEPAGE:{
    Offline:`Du bist offline`,
    Check:`Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut`,
    Again:`Versuchen Sie es erneut`
  },
  NOTFOUNDPAGE:{
    Page_Not_Found:'404,Seite nicht gefunden',
    Url:`Wir können die gesuchte Seite nicht finden. Bitte überprüfen Sie Ihre URL.`,
    Home:`Zurück zur Homepage`
  },
    DOCTOR_REGISTER_PAGE: {
      GENERAL_INFORMATION: {
        TITLE: {
          label: 'Titel',
          values: {
            'Herr': 'Herr',
            'Frau': 'Frau',
            'Dr.': 'Dr.',
            'Dr. med.': 'Dr. med.',
            'Dr. med. dent.': 'Dr. med. dent.',
            'Dr. med. vet.': 'Dr. med. vet.',
            'Dr. med. sci.': 'Dr. med. sci.',
            'Prof. Dr.': 'Prof. Dr.',
            'PD Dr.': 'PD Dr.',
            'apl. Prof. Dr.': 'apl. Prof. Dr.',
            'Dr. h.c.': 'Dr. h.c.',
            'Dr. mult.': 'Dr. mult.',
            'Prof. Dr. Dr.': 'Prof. Dr. Dr.',
            'Dr. med. habil.': 'Dr. med. habil.',
            'Dr. rer. nat.': 'Dr. rer. nat.',
            'Dr. rer. medic.': 'Dr. rer. medic.',
            'Dr. hc mult.': 'Dr. hc mult.',
            'Dr. Dr.': 'Dr. Dr.',
            'Prof. Dr. Dr. med. habil.': 'Prof. Dr. Dr. med. habil.'
          }
        },
        GENDER: {
          label: 'Wählen Sie Geschlecht aus',
          values:{
            'MALE': 'Männlich',
            'FEMALE': 'Weiblich',

          }
        },
        FIRSTNAME:{
          label: 'Vorname'
        },
        LASTNAME: {
          label: 'Nachname'
        },
        PROFILENAME:{
          label: 'Profilname'
        },
        PROFESSION:{
          label: 'Wählen Sie Ihren Beruf',
          values: {
            "1": "Doctor",
            "2": "Physio",
            "3": "Hebama",
            "4": "Life Coach"
          }
        },
        SPECIALITY: {
          label: "Wählen Sie Ihre Spezialität"
        },
        SPECIALIST: {
          label: "Wählen Sie Ihren Spezialisten"
        },
        LANGUAGES:{
          label: "Gesprochene Sprachen"
        },
        PRACTICE_SIZE:{
          label: "Geben Sie die Übungsgröße ein",
          values:{
            "1-5": "1-5",
            "6-10": "6-10",
            "11-20": "11-20",
            "20+": "20+"
          }
        }
      },
      CONTACT_DETAILS:{
        COUNTRIES:{
          label: "Wählen Sie Land aus"
        },
        STATES:{
          label: "Wählen Sie Land aus"
        },
        CITIES:{
          label: "Wählen Sie Stadt"
        },
        ADDRESS:{
          label: 'Haus Nr.'
        },
        STREET: {
          label: 'Straße'
        },
        AREA: {
          label: 'Wahrzeichen'
        },
        PINCODE: {
          label: 'PIN-Code'
        },
        WEBSITE:{
          label: 'Webseite'
        },
        MAIL: {
          label: 'Ihre E-Mail für Reservierungen'
        },
        ISD_CODE: {
          label: 'Wählen Sie Isd-Code',
          values:{
            "+91": "+91",
            "+43": "+43"
          }
        },
        PHONE: {
          label: 'Telefonnummer'
        },
      }
    },
    HOMEPAGE:{
      NAV_BAR_SECTION:{
        SITELOGO:{
          label: 'Finde dein medizinischen Dienstleister'
        },
        DOCTOR_OR_THERAPIST_BTN:{
          label: 'Sind Sie Ärzt:in oder Therapeut:in?'
        },
        HELP:{
          label: 'Hilfe'
        },
        LOGIN:{
          label: 'Login',
          label1: 'Abmelden',
          label2: 'Profil verwalten',
          label3: 'Eingeloggt',
        }
      },
      SEARCH_SECTION:{
        HEADER:{
          label: "Finden Sie Ihren",
          label1: "Hausarzt/",
          label2: "Hebamme/",
          label3: "",
          label4: "Kinderarzt",
          label5: "Orthopäde",
          label6: "Kardiologe",
          label7: "Gynäkologe",
          label8: "Hautarzt",
          label9: "Radiologe",
          label10: "Urologe",
          label11: "Neurologe",
          label12: "Nephrologe",
          label13: "HNO Arzt"
        },
        
        INPUT:{
          label: 'Suche nach einem Arzt oder Therapeuten',
          label1: 'Standort',
          label2: 'Suche',
          label3: 'Auf der Suche nach einem Arzt'
        },
        FILTER_CARDS:{
          label: 'Meistgesuchte Fachgebiete',
          label1: 'Coaching',
          label2: 'Hebamme',
          label3: 'Physiotherapeut:in',
          label4: 'Zahnärzt:in',
          label5: 'Gynäkloge',
          label6: 'Kinderärzt:in',
          label7: 'Hausärzt:in',
          label8: 'Orthopäde:in',
          label9: 'Augenärzt:in',
          label10: 'Plastische Chirurgie',
        },CITIES:{
        
          label: 'Anbieter nach Stadt finden',
          label1: 'Wien',
          label6: 'Klagenfurt',
          label11: 'Wiener Neustadt',
          label16: 'Leonding',
          label2: 'Graz',
          label7: 'Villach',
          label12: 'Wolfsberg',
          label17: 'Klosterneuburg',
          label3: 'Linz',
          label8: 'Wels',
          label13: 'Steyr',
          label18: 'Baden bei Wien',
          label4: 'Salzburg',
          label9: 'Sankt Pölten',
          label14: 'Feldkirch',
          label19: 'Leoben',
          label5: 'Innsbruck',
          label10: 'Dornbirn',
          label15: 'Bregenz',
          label20: 'Traun',
          label21: 'Hausärtzte',
          label22: 'Zahnärte',
          label23: 'Kinderzahnärte',
          label24: 'Kinderärzte',
          label25: 'Hautärzte',
          label26: 'Psychiater',
          label27: 'Therapeuten',
          label28: 'Augenärzte',
          label29: 'HNO-Ärzte',
          //label30: 'Notfallversorgung',
          label32: 'Krankenhäuser',
          label33: 'Gynäkologen'
        },
        CITIES1:{
          label: 'Anbieter nach Stadt finden',
          label1: 'Wien',
          label2: 'Klagenfurt',
          label3: 'Wiener Neustadt',
          label4: 'Leonding',
          label5: 'Graz',
          label6: 'Villach',
          label7: 'Wolfsberg',
          label8: 'Klosterneuburg',
          label9: 'Linz',
          label10: 'Wels',
          label11: 'Steyr',
          label12: 'Baden bei Wien',
          label13: 'Salzburg',
          label14: 'Sankt Pölten',
          label15: 'Feldkirch',
          label16: 'Leoben',
          label17: 'Innsbruck',
          label18: 'Dornbirn',
          label19: 'Bregenz',
          label20: 'Traun',
          label21: 'Hausärtzte',
          label22: 'Zahnärte',
          label23: 'Kinderzahnärte',
          label24: 'Kinderärzte',
          label25: 'Hautärzte',
          label26: 'Psychiater',
          label27: 'Therapeuten',
          label28: 'Augenärzte',
          label29: 'HNO-Ärzte',
          //label30: 'Notfallversorgung',
          label32: 'Krankenhäuser',
          label33: 'Gynäkologen'
        }
      },
      FOOTER_SECTION:{
          LINKS:{
            label: 'Startseite',
            label1: 'Über-uns',
            label2: 'Blog',
            label3: 'Kontakt',
            label4: 'Allgemeine-Nutzungsbedingungen',
            label5: 'Nutzungsbedingungen-der-Wesbsite',
            label6: 'Datenschutzhinweise',
            label7: 'Cookie-Richtlinie',
            label8: 'Umgang-mit-Cookies-und-Zustimmung',
            label9: 'Rechthinweis',
            label10: 'Rechtswidrige-Inhalte-Melden',
            label11: 'IMPRESSIUM',
            label12: 'Ihre-Praxis-bei-Artzme-auflisten',
            label13: 'Marketing-für-Ihre-Praxis',
            label14: 'Karriere',
            label15: 'Englisch',
            label16:'© 2025 Arztme GmbH, Alle Rechte vorbehalten'
          }
      },
      HAMBURG:{
        label:'Willkommen bei',
        label1:'Ihre Praxis',
        label2:' bei ARZTME auflisten'
      }
    },
    AreYouDoc:{
      HEADERS:{
        label: 'Der einfachste Weg zum Wachstum Ihrer Praxis',
        label1: 'Arztme hilft Ihnen, mehr neue Patienten zu gewinnen und zu binden - und spart Ihrer Praxis dabei wertvolle Zeit.',
        label2: 'Sind Sie ein Anbieter, der neue Patienten erreichen möchte?',
        label4: 'Füllen Sie Ihren Terminkalender mit freien Terminen in letzter Minute',
        label5: 'Steigern Sie Ihren Online-Ruf mit verifizierten Bewertungen',
        label6: 'Ihre Praxis bei Arztme auflisten',
        label3: 'Erreichen Sie Patienten in Ihrer Umgebung, die einen neuen Anbieter suchen',
        login:`Bitte melden Sie sich an, um fortzufahren`,
        doctor:`Sie sind bereits als Arzt registriert`,
      }
    },
    SEARCHFIL:{
      NAV_BAR_SECTION:{
        SITELOGO:{
          label: 'Finde dein medizinischen Dienstleister'
        },
        DOCTOR_OR_THERAPIST_BTN:{
          label: 'Sind Sie Ärzt:in oder Therapeut:in?'
        },
        HELP:{
          label: 'Hilfe'
        },
        LOGIN:{
          label: 'Login',
          label1:'Logout'
        }
      },
      PAGES:{
        prev:'VORHER',
        next:'NÄCHSTE'
      },
      INPUT:{
        label: 'Suche nach einem Arzt oder Therapeuten',
        label1: 'Standort',
        label2: 'Suche',
        label4: "Suche nach einem Arzt"
      },
      FILTERS:{
        label:'Sprache',
        label1:'Neuer Patienten Aufnahme',
        label2:'Versicherung',
        label3:'Verifiziert'
      },
      FILTERSINFO:{
        label:'Ja',
        label1:'Aufnahmestopp',
        label2:'Gesetzlich',
        label3:'Privatversicherte sowie Selbstzahlende',
        label4:'Englisch',
        label5:'Deutsch',
        label6:'Französisch',
        label7:'Italienisch',
        label8:'Kroatisch',
        label9:'Alle'
      },
      IMAGECARD:{
        label:'Aufnahme neuer Patienten',
        label1:'Durchschnittliche Wartezeit auf einen Termin',
        label2:'Praxis Modernisierungs Rating',
        label3:'Google-Bewertung',
        label4:'Montag',
        label5:'Dienstag',
        label6:'Mittwoch',
        label7:'Donnerstag',
        label8:'Freitag',
        label9:'Samstag',
        label10:'Termin Buchen',
        label11:'Öffnungszeiten',
        label12:'Tage',
        label13:'Ihre Antwort wird aufgezeichnet',
        label14:'Ups, da ist etwas schief gelaufen'
      },
      NOMATCHES:{
        label:'Leider wurden keine Treffer gefunden',
        label1:'Überprüfen Sie Ihre Schreibweise und versuchen Sie es erneut.',
        label2:'Versuchen Sie andere Schlüsselwörter.'
      },
      STARMODAL:{
        label:'Sie wird von vielen Gesundheitsdienstleistern angestrebt, aber nur wenigen Auserwählten gewährt, die sich wirklich auszeichnen.',
        label1:'Hochwertige Pflege',
        label2:'Hervorragende Betreuung',
        label3:'Außergewöhnlicher Gesundheitsdienstleister',
      }
    },
    FORGOTPAGE:{
      label:'Passwort Vergessen',
      label1:'Geben Sie Ihre E-Mail-Adresse ein',
      label2:'Link zum Zurücksetzen senden',
      label3:'Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.',
      label4:'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal',
      IncorrectEmail:'Bitte überprüfen Sie Ihre E-Mail-ID',
    },
    CONTACTUS:{
      label:`Nehmen wir Kontakt auf`,
      label1:'Bei Arztme bieten wir exzellenten Service',
      label2:'Verbinden Sie sich mit uns:',
      label3:'Kontaktieren Sie uns',
      label4:'Name',
      label5:'Email',
      label6:'Telefon',
      label7:'Ihre Anfrage',
    },
    LOGINPAGE:{
      label:`Login`,
      label1:'Ihre E-Mail',
      label2:'Passwort',
      label3:'Login',
      label4:`Sie haben kein Konto`,
      label5:'Melden Sie sich an',
      label6:'Passwort vergessen?',
      LoggedIn:'Sie sind jetzt angemeldet',
      InvalidCredentials:'Bitte überprüfen Sie Ihre Anmeldedaten',
      Trying:'Ich versuche mich anzumelden',
      ErrorOccured:'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut'
    },
    SIGNUPPAGE:{
      label:`Ein Konto erstellen`,
      label1:'Ihr Name',
      label2:'Ihre E-Mail',
      label3:'Passwort',
      label4:`Wiederholen Sie Ihr Passwort`,
      label5:'Ich stimme allen Aussagen zu',
      label6:'Nutzungsbedingungen',
      label7:'Registrieren',
      label8:'Sie haben bereits ein Konto?',
      label9:'Melden Sie sich hier an'
    },
    ABOUTUSPAGE:{
      label:'Über Arztme',
      label1:'Tausende von Anbietern.',
      label2:'Eine Website.',
      label3:'Der schnellste und einfachste Weg, Ärzte und Therapeuten in Ihrer Nähe zu finden',
      label4:'Beratung Vereinbaren',
    },
    IMPRESSIUMPAGE:{
      label:'Impressum'
    },
    CAREERSPAGE:{
      label:'Karrieren',
      label1:'Werden Sie Teil unseres Teams',
      label2:`Bei Arztme™ bauen wir etwas Neues, Kühnes und aufregend. Während wir wachsen, suchen wir nach leidenschaftlichen, innovativen und engagierten Personen, die bereit sind, etwas zu bewirken. Egal, ob Sie ein Designer, Entwickler, Vermarkter oder Stratege sind, wenn Sie nach einem Ort suchen, an dem Sie beitragen, lernen und wachsen können, würden wir uns freuen, von Ihnen zu hören.`,
      label3:'Wir sind ein schnelllebiges Startup, das von Kreativität, Zusammenarbeit und den Drang, reale Probleme zu lösen. Wir glauben an unser Team zu befähigen, Verantwortung zu übernehmen, neue Dinge auszuprobieren und die Grenzen des Möglichen zu erweitern.',
      label4:`Warum bei uns arbeiten?`,
      label5:'Flexibel Umfeld:',
      label6:'Wir legen Wert auf eine ausgewogene Work-Life-Balance und bieten Flexibilität bei und wann Sie arbeiten.',
      label7:'Wachstum:',
      label8:'Als wachsendes Startup-Unternehmen bieten wir Ihnen viel Raum, um Ihre Fähigkeiten zu erweitern, neue Herausforderungen anzunehmen und Ihre Karriere voranzutreiben.',
      label9:'Kollaborative Kultur:',
      label10:'Sie arbeiten eng mit einem leidenschaftlichen, unterstützenden Team von Denkern, die etwas bewirken wollen.',
      label11:'Machen Sie Einfluss:',
      label12:'Ihre Arbeit wird vom ersten Tag an von Bedeutung sein. Bei Arztme™ schätzen wir den Beitrag jedes Einzelnen und glauben, dass jeder eine wichtige Rolle für unseren Erfolg.',
      label13:'Aktuelle Stellenausschreibungen',
      label14:'Wir nehmen fortlaufend Bewerbungen an, wenn Sie also wenn Sie von unserer Arbeit begeistert sind, aber keine Stelle sehen, die perfekt passen, können Sie sich gerne bei uns melden! Wir sind immer auf der Suche nach talentierten Menschen, die unser Team verstärken.',
      label15:'Wie man sich bewirbt:',
      label16:'Bitte reichen Sie Ihren Lebenslauf und ein kurzes Anschreiben ein, in dem Sie erklären, warum Sie warum Sie sich für diese Gelegenheit interessieren. Fügen Sie unbedingt eine Mappe oder Arbeitsproben (falls zutreffend). Wir werden die eingehenden Bewerbungen prüfen und laden qualifizierte Bewerber zu einem Vorstellungsgespräch ein.',
      label17:'Sind Sie bereit, etwas zu bewirken? Bewerben Sie sich noch heute!',
      label18:'',
      label19:'',
      label20:'',
    },
    RESETPASSWORDPAGE:{
      label:`Neues Passwort erstellen`,
      label1:'Neues Passwort',
      label2:'Bestätigen Sie das neue Passwort',
      label3:'Passwort zurücksetzen',
      label4:'Passwort erfolgreich geändert',
      label5:'Ups, da ist etwas schief gelaufen'
    },
    MARKETTINGPAGE:{
      label:'Vermarktung Ihrer Praxis',
      label1:'Tausende von Anbietern.',
      label2:'Eine Website.',
      label3:'Der schnellste und einfachste Weg, Ärzte und Therapeuten in Ihrer Nähe zu finden',
      label4:'Beratung Vereinbaren',
    },
    DOCPROFILEPAGE:{
      label:'Google Bewertung',
      label1:'Bearbeiten Sie Ihr Profil',
      label2:'Termin buchen',
      label3:'Über mich',
      label4:'Karte anzeigen',
      label5:'Versicherung',
      label6:'Zeitangaben',
      label7:'Abschluss',
      label8:'Zertifikate',
      label9:'Fotos und Videos',
      label10:'Personen in meinem Netzwerk',
      label11:'Zusammenfassung der Arztme-Rezensionen',
      label12:'Schreiben Sie eine Bewertung',
      label13:'Melden Sie sich an, um eine Bewertung zu schreiben',
      label14:'Bewertungen',
      label15:'Mehr anzeigen',
      label16:'Bearbeiten Sie Ihre Bewertung',
      label17:'Veröffentlichen Sie Ihre Bewertung',
      label18:'Ihr Bewertungsthema',
      label19:'Ihr Bewertungsinhalt',
      label20:'',
    },
    BOOKINGMODAL:{
      label:'Buchen Sie einen Termin',
      label1:`Ich verstehe, dass Sie Online-Termine bei Ihrem Wunscharzt buchen möchten, diese sind jedoch nicht über GoArzt verfügbar. Wir sind hier, um zu helfen! Wenn Sie möchten, können wir Ihnen bei der Vereinbarung eines Termins in deren Büro oder bei einem anderen Anbieter in der Nähe behilflich sein.`,
      label2:'Bitte beantworten Sie die folgenden Fragen, damit wir die beste Option für Sie finden können',
      label3:'Vor- und Nachname',
      label4:'Welche Tageszeit passt am besten zu Ihnen?',
      label5:'Alle bestimmten Tage oder Zeiten, die Sie vermeiden sollten',
      label6:'Ist dieser Termin dringend?',
      label7:'Welche Krankenversicherung haben Sie?',
      label8:'Versicherungsnummer',
      label9:'Gibt es sonst noch etwas, was wir wissen sollten? (z. B. Sprachpräferenzen) (Optional)',
      label10:'Ihre Telefonnummer.',
      label11:'E-Mail-Adresse',
      label12:'Sie stimmen unseren Allgemeinen Geschäftsbedingungen zu',
      label13:'Einreichen',
      label14:'Morgen',
      label15:'Nachmittag',
      label16:'Abend',
      label17:'Ja',
      label18:'Nein, ich kann eine Woche oder länger warten',
      label19:'Öffentlich',
      label20:'Privat oder Selbstzahler',
      label21:'Versicherungsnummer',
      label22:'Telefonnummer',
      label23:'Geben Sie Ihre E-Mail-Adresse ein',
      label24:'Geben Sie Ihren Namen ein',
      label25:'Bestimmte Tage/Zeiten',
      label26:'Der vollständige Name ist erforderlich.',
      label27:'Bitte wählen Sie ein bevorzugtes Zeitfenster aus.',
      label28:'Bitte geben Sie die bevorzugten Tage/Uhrzeiten an, die Sie meiden möchten.',
      label29:'Bitte geben Sie an, ob der Termin dringend ist.',
      label30:'Bitte wählen Sie eine Versicherungsart aus.',
      label31:'Versicherungsnummer ist erforderlich.',
      label32:'Bitte geben Sie eine gültige 10-stellige Telefonnummer ein.',
      label33:'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      label34:'Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen.',
      label35:'',
      post:`Veröffentlichen Sie Ihre Bewertung`,
      sucess:`Rezension erfolgreich gepostet`,
      wrong:`Hoppla, etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal`
    },
    USERREGISTERTOAST:{
      welcome:`Willkommen bei Arztme`,
      wrong:`Ups, da ist etwas schief gelaufen`
    },
    DOCEDIT:{
      SIDEBAR:{
        label:'Grundlegende Informationen',
        label1:'Kontaktinformationen',
        label2:'Über Informationen',
        label3:'Versicherungsinformationen',
        label4:'Bildungsinformationen',
        label5:'Öffnungszeiten',
        label6:'BG-Bilder',
        label7:'Karten',
      },
      BASICINFO:{
        label:'Wählen Sie Ihren Titel aus',
        label1:'Vorname',
        label2:'Nachname',
        label3:'Beruf',
        label4:'Spezialität',
        label5:'Sprache',
        label6:'Änderungen speichern',
        ProfileName:'Profilname',
        UpdateInProccess:'Details werden aktualisiert',
        DetailsUpdated:'Ihre Daten werden aktualisiert',
        FailedToUpdate:'Details konnten nicht aktualisiert werden. Bitte wenden Sie sich an Arztme'
      },
      CONTACTINFO:{
        label:'Haus Nummer',
        label1:'Straße',
        label2:'Bereich',
        label3:'Land',
        label4:'Zustand',
        label5:'Stadt',
        label6:'PIN Code',
        label7:'E-Mail-ID',
        label8:'Isd Code',
        label9:'Telefon',
        label10:'Änderungen speichern',
        website:'Webseite',
        UpdateInProccess:'Details werden aktualisiert',
        DetailsUpdated:'Ihre Daten werden aktualisiert',
        FailedToUpdate:'Details konnten nicht aktualisiert werden. Bitte wenden Sie sich an Arztme'
      },
      ABOUTINFO:{
        label:'Über mich',
        label1:'Neue Patientenakzeptanz',
        label2:'Durchschnittliche Wartezeit für einen Termin (in Tagen)',
        label3:'Änderungen speichern',
        label4:'About Me German',
        UpdateInProccess:'Details werden aktualisiert',
        DetailsUpdated:'Ihre Daten werden aktualisiert',
        FailedToUpdate:'Details konnten nicht aktualisiert werden. Bitte wenden Sie sich an Arztme',
        TranslationApproval:'Bitte genehmigen Sie die deutsche Übersetzung vor dem Absenden.'
      },
      MAPINFO:{
        UpdateInProccess:'Details werden aktualisiert',
        DetailsUpdated:'Ihre Daten werden aktualisiert',
        FailedToUpdate:'Details konnten nicht aktualisiert werden. Bitte wenden Sie sich an Arztme',
      },
      BACKGROUNDIMAGES:{
        IMAGEUPLOADING:'Bild wird hochgeladen',
        IMAGEUPLOADED:'Bild erfolgreich hochgeladen',
        IMAGEUPLOADINGFAILED:'Das Hochladen des Bildes ist fehlgeschlagen',
        IMAGEDELETING:'Bild wird entfernt',
        IMAGEDELTED:'Bild erfolgreich gelöscht',
        IMAGEDELETINGFAILED:'Bildlöschung fehlgeschlagen',
        UPLOAD:'Bild hochladen',
        UPLOADING:'Hochladen',
        CANCEL:'Stornieren',
        BACKGROUNDIMAGE:'Hintergrundbilder'
      },
      INSURANCEINFO:{
        label:'Versicherungsunternehmen',
        label1:'Öffentliche Versicherung',
        label2:'Änderungen speichern',
        UpdateInProccess:'Details werden aktualisiert',
        DetailsUpdated:'Ihre Daten werden aktualisiert',
        FailedToUpdate:'Details konnten nicht aktualisiert werden. Bitte wenden Sie sich an Arztme'
      },
      EDUCATIONINFO:{
        label:'Abschluss',
        label1:'Zertifikate',
        label2:'Änderungen speichern',
        UpdateInProccess:'Details werden aktualisiert',
        DetailsUpdated:'Ihre Daten werden aktualisiert',
        FailedToUpdate:'Details konnten nicht aktualisiert werden. Bitte wenden Sie sich an Arztme'
      },
      COOKIECONSENT:{
        label:'Verwalten Sie Ihre Cookie-Einstellungen',
        label1:'Wir respektieren die Privatsphäre unserer Nutzer. Ihre Daten, Ihre Entscheidungen.',
        label2:'The Following are necessary cookies and required by the webiste to function properly.These cookies cannot be denied',
        label3:'Sind Sie damit einverstanden, die Sprach-Cookies zu akzeptieren?',
        label4:'Do you consent to accept the location Cookies?These Cookies Store your Location to provide smooth search experience',
        label5:'Einstellungen speichern',
        CookiesConsent:'Einwilligung zu Cookies',
        CookiesDescription:'Diese Website verwendet Cookies, um Ihnen ein besseres und relevanteres Surferlebnis zu ermöglichen.',
        Accept:'Akzeptieren',
        Decline:'Ablehnen'
      },
      PROFILEHEADER:{
        label:'Bild erfolgreich hochgeladen',
        label1:'Oops Something Went WrongUps, da ist etwas schief gelaufen'
      }
    },
    NAVBAR:{
      listing:'Auflistung',
      login:'Einloggen',
      logout:'Ausloggen',
      signup:'Anmelden'
    }

  }
  
  export { germanStrings }; 