import React from 'react';
import { WifiOff } from 'lucide-react';
import { useLanguage } from '../../features_new/homepage/components/LanguageContext';

const OfflineStatus = () => {
  // Get current window width for responsive behavior
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      height: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f9fafb',
      padding: windowWidth < 640 ? '16px' : '24px'
    },
    card: {
      width: '100%',
      maxWidth: windowWidth < 640 ? '100%' : '400px',
      margin: windowWidth < 640 ? '0' : '0 16px',
      padding: windowWidth < 640 ? '24px 16px' : '48px 24px',
      backgroundColor: 'white',
      borderRadius: windowWidth < 640 ? '6px' : '8px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    iconContainer: {
      backgroundColor: '#fee2e2',
      padding: windowWidth < 640 ? '16px' : '24px',
      borderRadius: '50%',
      marginBottom: windowWidth < 640 ? '16px' : '24px'
    },
    textContainer: {
      textAlign: 'center',
      marginBottom: windowWidth < 640 ? '16px' : '24px',
      width: '100%'
    },
    heading: {
      fontSize: windowWidth < 640 ? '20px' : '24px',
      fontWeight: 'bold',
      color: '#111827',
      marginBottom: '8px'
    },
    subtext: {
      fontSize: windowWidth < 640 ? '14px' : '16px',
      color: '#6b7280',
      maxWidth: '280px',
      margin: '0 auto',
      lineHeight: '1.5'
    },
    button: {
      backgroundColor: '#dc2626',
      color: 'white',
      padding: windowWidth < 640 ? '10px 20px' : '12px 24px',
      borderRadius: windowWidth < 640 ? '4px' : '6px',
      border: 'none',
      fontSize: windowWidth < 640 ? '14px' : '16px',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      width: windowWidth < 640 ? '100%' : 'auto'
    },
    buttonHover: {
      backgroundColor: '#b91c1c',
      transform: 'translateY(-1px)'
    }
  };

  const [isHovered, setIsHovered] = React.useState(false);
  const {langStrings,language} = useLanguage();
  const OT = langStrings.OFFLINEPAGE;
  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.iconContainer}>
          <WifiOff 
            size={windowWidth < 640 ? 32 : 48} 
            color="#dc2626" 
          />
        </div>
        
        <div style={styles.textContainer}>
          <h1 style={styles.heading}>{OT.Offline}</h1>
          <p style={styles.subtext}>
          {OT.Check}
          </p>
        </div>

        <button 
          onClick={() => window.location.reload()}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            ...styles.button,
            ...(isHovered ? styles.buttonHover : {})
          }}
        >
          {OT.Again}
        </button>
      </div>
    </div>
  );
};

export default OfflineStatus;