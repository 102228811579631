import { useState, useEffect, useRef, useCallback } from 'react';
import './DocSearch.css'
import RenderMap from './doctor_search/RenderMap.js';
import ImageCard from './ImageCard.jsx';
import SearchFil from './SearchFil.jsx';
import Footer from '../../homepage/components/Footer.jsx';
import { useLanguage } from '../../homepage/components/LanguageContext.js';
import MobileImageCard from './MobileImageCard.jsx';
import NavBarList from './NavBarList.jsx';
import SearchBar from './SearchBarListing.jsx';
import { useLocation } from 'react-router-dom';
import DoctorCardSkeleton from './DoctorCardSkeleton.jsx';
import VerticalMapSkeleton from './VerticalMapSkeleton.jsx';
import { useOnlineStatus } from '../../../common_components/hooks/OnlineStatus.js';
import OfflineStatus from '../../../common_components/Offline/OfflineStatus.jsx';

var _ = require('lodash');

export default function DocSearchView({
  searchTextTop,
  isSkeletonLoading,
  isdoc,
  getDocCountMatchingStr,
  profileImagePrefix,
  count,
  users,
  unsetAllDocs,
  setSelectedDoc,
  getDocLocAddressStr,
  getDocLocAddressStr1,
  getLanguageString,
  selectedDoc,
  searchFormData,
  handleFormChange,
  handleFormSubmitNew,
  setSearchFormData,
  currentPage, 
  handlePrevPage, 
  handleNextPage, 
  goToPage, 
  totalPages,
  searchkeyEn,
  searchkeyDe,
  locationkeyEn,
  locationkeyDe
}) {
  const divRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };
  const mapRef = useRef(null);
  useEffect(() => {
    const updatePlaceholder = () => {
      setIsTablet(window.innerWidth < 1024);
    };
    updatePlaceholder();
    window.addEventListener('resize', updatePlaceholder);
    return () => window.removeEventListener('resize', updatePlaceholder);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        setIsMobile(divRef.current.offsetWidth < 768);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [selectedLanguage, setSelectedLanguage] = useState('');
  const { langStrings} = useLanguage();
  const LT = langStrings.SEARCHFIL;

  const pageNumbers = [];
  const maxPageNumbersToShow = 5;

  if (totalPages <= maxPageNumbersToShow) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (Number(searchFormData.currPage) <= maxPageNumbersToShow - 2) {
      for (let i = 1; i <= maxPageNumbersToShow; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    } else if (Number(searchFormData.currPage) > totalPages - (maxPageNumbersToShow - 2)) {
      pageNumbers.push(1);
      pageNumbers.push('...');
      for (let i = totalPages - (maxPageNumbersToShow - 1); i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push('...');
      for (let i = Number(searchFormData.currPage) - 1; i <= Number(searchFormData.currPage) + 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    }
  }

  const [mapHeight, setMapHeight] = useState('');
  const [hoveredDoctorId, setHoveredDoctorId] = useState(null);
  const [imageCardHeights, setImageCardHeights] = useState({});
  const [totalImageCardHeight, setTotalImageCardHeight] = useState(0);

  const handleCardHeight = (id, height) => {
    setImageCardHeights((prevHeights) => {
      if (prevHeights[id] !== height) {
        const newHeights = { ...prevHeights, [id]: height };
        const totalHeight = Object.values(newHeights).reduce((acc, h) => acc + h, 0);
        setTotalImageCardHeight(totalHeight);
        return newHeights;
      }
      return prevHeights;
    });
  };
  const handleDoctorHover = useCallback((doctorId) => {
    setHoveredDoctorId(doctorId);
    
    if (doctorId && mapRef.current && users?.doctorList) {
      const doctor = users.doctorList.find(doc => doc.id === doctorId);
      if (doctor?.centers?.[0]) {
        const location = {
          lat: Number(doctor.centers[0].lat),
          lng: Number(doctor.centers[0].lng)
        };
        mapRef.current.centerOnLocation(location);
      }
    }
  }, [users?.doctorList]);
  useEffect(() => {
    if (Object.keys(imageCardHeights).length > 0) {
      console.log('Total ImageCard height:', totalImageCardHeight);
    }
  }, [totalImageCardHeight]);



  const isOnline = useOnlineStatus();
  if(!isOnline) {
    return <OfflineStatus />
  }

  return (
    <div ref={divRef}>
      <div   className="container">
        <NavBarList />
        <br />
        <SearchBar 
          searchkeyEn={searchkeyEn}
          searchkeyDe={searchkeyDe}
          locationkeyEn={locationkeyEn}
          locationkeyDe={locationkeyDe}
        />
        {
          isMobile?
          <div className='d-flex flex-column justify-content-center'>
            <button 
              className="btn d-md-none "
              style={{margin :'10px 12px',color:'black'}} 
              onClick={toggleFilters} 
            >
            {
              filtersVisible 
              ? 'Hide Filters'
              : 'Show Filters'
            }
          </button>
          {
            filtersVisible ? <SearchFil selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}/>:<div></div>
          }
          </div>
          :
          <SearchFil selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}/>
        }
        <br />
        <div  style={{display:'flex'}}>
        
          {
          isSkeletonLoading ? (
            isMobile ? (
              <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                {[...Array(5)].map((_, index) => (
                  <DoctorCardSkeleton key={index} />
                ))}
              </div>
            ) : (
              <div style={{display:'flex',width:'100%'}}>
    <div style={{width:'70%'}}>
      <div style={{display:'flex',flexDirection:'column',padding:'18px'}}>
        {[...Array(5)].map((_, index) => (
          <DoctorCardSkeleton key={index} />
        ))}
      </div>
    </div>
    <VerticalMapSkeleton />
  </div>
            )
          ):
          
          !users?.doctorList || users?.doctorList.length == 0 ?  
          
          isMobile?
          <div style={{display:'flex'}}>
            <div  className='NoMatch'>
              <div>
                <img class='' style={{height:'25vh',margin:'0px 10px'}} src="/no-match.png" alt="" />
              </div>
              <p style={{color:"#7f7f7f"}} className='NoMatchLabel' >{LT.NOMATCHES.label}</p>
              <p style={{color:"#7f7f7f"}} className='NoMatchLabel1' >{LT.NOMATCHES.label1}</p>  
              <p style={{color:"#7f7f7f"}} className='NoMatchLabel2' >{LT.NOMATCHES.label2}</p>
            </div>
          </div>
         :<div style={{display:'flex',width:'100%'}}>
         <div style={{width:'70%'}}>
               <div style={{display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            textAlign:'center',
            paddingTop:'125px'}}  >
                 <div>
                   <img class='' style={{height:'40vh',margin:'0px 10px'}} src="/no-match.png" alt="" />
                 </div>
                 <p style={{color:'#7f7f7f',fontSize:'45px',fontWeight:'bold'}}>{LT.NOMATCHES.label}</p>
                 <p style={{color:'#7f7f7f',fontSize:'25px',margin:'0px',padding:'0px'}}>{LT.NOMATCHES.label1}</p>  
                 <p style={{color:'#7f7f7f',fontSize:'25px',marginBottom:'10px'}}>{LT.NOMATCHES.label2}</p>
               </div>
         </div>
           <div style={{width:'30%',margin:'0px 20px', padding:'0px 40px'}} class=" vh-100">
             <RenderMap  srpData={users} selectedDoc={selectedDoc} />
           </div>
         </div>
         :
         isMobile?
                
         <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
         {users?.doctorList.map((user) => (
         <MobileImageCard 
         key={user.id}
         isdoc={isdoc}
         user={user}
         searchTextTop={searchTextTop}
         getDocCountMatchingStr={getDocCountMatchingStr}
         profileImagePrefix={profileImagePrefix}
         count={count}
         users={users}
         unsetAllDocs={unsetAllDocs}
         setSelectedDoc={setSelectedDoc}
         getDocLocAddressStr={getDocLocAddressStr}
         getLanguageString={getLanguageString}
         selectedDoc={selectedDoc}
         searchFormData={searchFormData}
         handleFormChange={handleFormChange}
         handleFormSubmitNew={handleFormSubmitNew}
         setSearchFormData={setSearchFormData}/>
       ))}  
       </div>

         
          
        : isTablet?
        <div style={{display:'flex',width:'100%'}}> 
        <div style={{display:'flex',flexDirection:'column',width:'100%',padding:'18px'}}>
            {users?.doctorList.map((user) => (
            <ImageCard 
            key={user.id}
            id={user.id}
            onHeightChange={handleCardHeight}
            isdoc={isdoc}
            user={user}
            searchTextTop={searchTextTop}
            getDocCountMatchingStr={getDocCountMatchingStr}
            getLanguageString={getLanguageString}
            profileImagePrefix={profileImagePrefix}
            count={count}
            users={users}
            unsetAllDocs={unsetAllDocs}
            setSelectedDoc={setSelectedDoc}
            getDocLocAddressStr1={getDocLocAddressStr1}
            selectedDoc={selectedDoc}
            searchFormData={searchFormData}
            handleFormChange={handleFormChange}
            handleFormSubmitNew={handleFormSubmitNew}
            setSearchFormData={setSearchFormData}
            onHover={handleDoctorHover} 
            isHovered={hoveredDoctorId === user.id}  
            />
          ))}
          </div>
          </div>
        :
        <div style={{display:'flex',width:'100%'}}> 
          <div style={{display:'flex',flexDirection:'column',width:'80%',padding:'18px'}}>
            {users?.doctorList.map((user) => (
              <ImageCard 
                key={user.id}
                id={user.id}
                onHeightChange={handleCardHeight}
                isdoc={isdoc}
                user={user}
                searchTextTop={searchTextTop}
                getDocCountMatchingStr={getDocCountMatchingStr}
                profileImagePrefix={profileImagePrefix}
                count={count}
                users={users}
                unsetAllDocs={unsetAllDocs}
                setSelectedDoc={setSelectedDoc}
                getDocLocAddressStr1={getDocLocAddressStr1}
                getLanguageString={getLanguageString}
                selectedDoc={selectedDoc}
                searchFormData={searchFormData}
                handleFormChange={handleFormChange}
                handleFormSubmitNew={handleFormSubmitNew}
                setSearchFormData={setSearchFormData}
                onHover={handleDoctorHover}  
            isHovered={hoveredDoctorId === user.id}  
              />
            ))}
            
          </div> 
          <div className='mapsss'>
            <div id="sticky" class="w-100">
              <RenderMap  srpData={users} selectedDoc={selectedDoc} hoveredDoctorId={hoveredDoctorId} ref={mapRef}/>
            </div>
          </div>
        </div>
}</div>
    <div className="pagination-controls" >
        <button
          onClick={handlePrevPage}
          //disabled={searchFormData.currPage === 0}
          className="btn selected_btn1"
        >
          {LT.PAGES.prev}
          
        </button>

        {pageNumbers.map((page, index) =>
          page === '...' ? (
            <span key={index} style={{ margin: '0 10px',color:'#A0A0A0' }}>...</span>
          ) : (
            <button
              key={index}
              onClick={() => goToPage(page - 1)}
              className={`btn ${page === Number(searchFormData.currPage) + 1 ? 'selected_btn' : 'selected_btn1'}`}
              style={{ margin: '0 5px' }}
            >
              {page}
            </button>
          )
        )}

        <button
          onClick={handleNextPage}
          //disabled={searchFormData.currPage > totalPages - 1}
          className="btn selected_btn1"
        >
          {LT.PAGES.next}
        </button>
      </div>
      <style jsx global>{`
        @keyframes pulse {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
      <style jsx>
        {`
        #sticky {
          position: sticky;
          top: 50px;
          left: 1400px;   
          margin:0px ;
          height:20%;
        }

        
        @media (max-width:320px){
          .NoMatch{
            padding-top:0px;
            display:flex;
            flex-direction:column;
            justify-content:center;
            text-align:center;
          }
          .NoMatchLabel{
            color:#7f7f7f;
            font-size:25px;
            font-weight:bold;
          }
          .NoMatchLabel{
            color:#7f7f7f;
            font-size:25px;
            font-weight:bold;
          }
          .NoMatchLabel1{
            color:#7f7f7f;
            font-size:14px;
            margin:0px;
            padding:0px;
          }
          .NoMatchLabel2{
            color:#7f7f7f;
            font-size:14px;
            margin-bottom:10px;
           
          }
        }
        @media (max-width:375px){
          .NoMatch{
            display:flex;
            flex-direction:column;
            justify-content:center;
            text-align:center;
            padding-top:0px;
          }
          .NoMatchLabel{
            color:#7f7f7f;
            font-size:25px;
            font-weight:bold;
          }
          .NoMatchLabel1{
            color:#7f7f7f;
            font-size:14px;
            margin:0px;
            padding:0px;
          }
          .NoMatchLabel2{
            color:#7f7f7f;
            font-size:14px;
            margin-bottom:10px;
           
          }
        }
        @media (max-width:430px){
          .NoMatch{
            display:flex;
            flex-direction:column;
            justify-content:center;
            text-align:center;
            padding-top:0px;
          }
          .NoMatchLabel{
            color:#7f7f7f;
            font-size:25px;
            font-weight:bold;
          }
          .NoMatchLabel1{
            color:#7f7f7f;
            font-size:14px;
            margin:0px;
            padding:0px;
          }
          .NoMatchLabel2{
            color:#7f7f7f;
            font-size:14px;
            margin-bottom:10px;
           
          }
        }
        @media (max-width:1024px){
          .sticky{
            position: sticky;
            position: -webkit-sticky;
            top: 0px;
            left: 1400px;
            width: 90%;
            margin:0px 10px;
          }
          #wrapper{
          
          }
        }

        
        @media (max-width:1440px){
          .map-width{
            width:80%;
          }
          .filTop{
            padding: 100px 0px 0px;
          }
          .sticky{
            position: sticky;
            position: -webkit-sticky;
            top: 0px;
            left: 1400px;
            width: 90%;
            margin:0px 20px;
          }
          
          .NoMatch{
            display:flex;
            flex-direction:column;
            justify-content:center;
            text-align:center;
            padding-top:0px;
          }
        }
        @media (max-width:1920px){
          .map-width{
            width:30%;
          }
          .mapsss{
            width:20%;
            
            padding:28px 0px;
          }
          .sticky{
            position: sticky;
            position: -webkit-sticky;
            top: 0px;
            left: 1400px;
            width: 90%;
            margin:20px 20px;
          }
        }
          @media (max-width:10000px){
          .map-width{
            width:30%;
          }
          .mapsss{
            width:20%;
            
            padding:28px 0px;
          }
          .sticky{
            position: sticky;
            position: -webkit-sticky;
            top: 0px;
            left: 1400px;
            width: 90%;
            margin:20px 20px;
          }
        }
        `}
      </style>
    </div>
    <div>
    <Footer />
  </div>
  </div>
  );
}