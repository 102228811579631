import React, { useEffect, useState } from "react";
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import { useOutletContext } from "react-router-dom";
import { useLanguage } from "../../../homepage/components/LanguageContext";
import DemoComp from "./DemoComp";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { Modal, Button } from 'react-bootstrap';
import { toast } from "sonner";

const MapInfo = () => {
  const { user,setUser } = useOutletContext();
  const { language, langStrings } = useLanguage();
  const authUser = useAuthUser();
  const [location, setLocation] = useState({
    lat: user.centers[0]?.lat || 47.80164,
    lng: user.centers[0]?.lng || 13.04541,
  });
  const [tempLocation, setTempLocation] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [displayLocation, setDisplayLocation] = useState(location);
  const ST = langStrings.DOCEDIT.MAPINFO;
  const API_KEY = process.env.REACT_APP_GEOCODING_API_KEY;

useEffect(() => {
  const newLocation = {
    lat: user?.centers[0]?.lat || 47.80164,
    lng: user?.centers[0]?.lng || 13.04541,
  };
  setLocation(newLocation);
  setDisplayLocation(newLocation);
}, [user]);
  const handleMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    const updatedLocation = { lat: newLat, lng: newLng };
    setDisplayLocation(updatedLocation);
    setHasUnsavedChanges(true);
  };

  const handleSaveChanges = async () => {
    const updatedCenters = user.centers.map((center) => ({
      ...center,
      lat: displayLocation.lat,
      lng: displayLocation.lng,
    }));

    const updateData = {
      centers: updatedCenters,
    };

    try {
      toast.info(ST.UpdateInProccess, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      const LanguageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken || '';
      const response = await axios.put(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`,
        updateData,
        {
          headers: {
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          },
        }
      );
      setTimeout(async()=>{
      if (response.status === 200) {
        console.log('Data updated successfully:', response.data);
        toast.success(ST.DetailsUpdated, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px',
            padding: '10px 20px',
            minWidth: '200px',
          },
        });
        setUser(response.data);
        //await callUpdatedUser();
        setLocation(displayLocation);
        setHasUnsavedChanges(false);
      }
    },300)
    } catch (err) {
      toast.error(ST.FailedToUpdate, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px',
          padding: '10px 20px',
          minWidth: '200px',
        },
      });
      console.error('API error:', err);
    }
  };

  const callUpdatedUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor/id?doctorId=${user.id}&enrich=true`
      );
      if (response.status === 200) {
        console.log('User fetched successfully:', response.data);
        user.centers = response.data.centers;
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleMapClick = (e) => {
    if (e && e.detail.latLng) {
      const lat = e.detail.latLng.lat;
      const lng = e.detail.latLng.lng;
      const updatedLocation = { lat, lng };
      setDisplayLocation(updatedLocation);
      setHasUnsavedChanges(true);
    }
  };

  return (
   
      
      <div style={{ height: "500px", width: "100%", position: "relative" }}>
      
        <APIProvider apiKey={API_KEY}>
          <Map
            defaultCenter={displayLocation}
            defaultZoom={15}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            mapId="DEMO_MAP_ID"
            onClick={handleMapClick}
          >
            <DemoComp handleMapClick={handleMapClick} />
            <AdvancedMarker
              position={displayLocation}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
            
          </Map>
          
        <Button 
          variant="primary" 
          onClick={handleSaveChanges}
          disabled={!hasUnsavedChanges}
          style={{ 
            backgroundColor: '#f35b2a',
            border: 'none',
            zIndex: 1000,
            position: 'absolute',
            right: '10px',
            top: '10px'
          }}
        >
          Save Location
        </Button>
      
        </APIProvider>
      </div>
    
  );
};

export default MapInfo;