import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import NavbarForgot from './NavbarForgot.jsx';
import Footer from './Footer.jsx';
import { useLanguage } from './LanguageContext';
import { MDBInput } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useAuthUser } from 'react-auth-kit';

const ResetPassword = () => {
    const [formData, setFormData] = useState({
        password: '',
        passwordconfirm: '',
    });
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);  
    const [showPassword1, setShowPassword1] = useState(false); 
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [loginButton, setShowLoginButton] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const authUser = useAuthUser();
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
        const newErrors = { ...errors };

    if (name === 'password') {
        if (value.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long.';
        } else {
            delete newErrors.password;
        }

        if (formData.passwordconfirm && formData.password !== formData.passwordconfirm) {
            newErrors.passwordconfirm = 'Passwords do not match.';
        } else {
            delete newErrors.passwordconfirm;
        }
    }

    if (name === 'passwordconfirm') {
        if (value !== formData.password) {
            newErrors.passwordconfirm = 'Passwords do not match.';
        } else {
            delete newErrors.passwordconfirm;
        }
    }

    setErrors(newErrors);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev); 
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1((prev) => !prev); 
    };

    const token = new URLSearchParams(location.search).get('token');
    
    
    useEffect(() => {
        const validateToken = async () => {
            try {
                const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
                
                const response = await fetch(`${process.env.REACT_APP_JAVA_BASE_URL}${process.env.REACT_APP_CONFIRM_PASSWORD}?token=${token}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json','X-LOCALE': `${LanguageLocale}`,'X-AUTHTOKEN': token },
                });

                if (response.ok) {
                    console.log(`OK`,response);
                    setIsTokenValid(true);
                } else {
                    setErrors({ token: 'Invalid or Expired Session.' });
                }
            } catch (error) {
                setErrors({ token: 'An error occurred during token validation.' });
            }
        };

        if (token) validateToken();
        if(token == null){
            setErrors({ token: 'Invalid or Expired Session.' });
        }
    }, [token]);
    const navigate = useNavigate();
    const { language, langStrings, toggleLanguage } = useLanguage();
    const ST = langStrings.RESETPASSWORDPAGE;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.passwordconfirm) {
            setErrors({ passwordconfirm: 'Passwords do not match.' });
            return;
        }
        
        try {
            const url = `${process.env.REACT_APP_JAVA_BASE_URL}${process.env.REACT_APP_CONFIRM_PASSWORD}?token=${token}&newPassword=${formData.password}`;
            const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json','X-LOCALE': `${LanguageLocale}`,'X-AUTHTOKEN': token },
            });
            console.log(`came here try`,response)
            if (response.ok) {
                setPasswordChanged(true);
                setShowLoginButton(true);
                toast.success(ST.label4, {
                    position: 'top-center',
                    duration: 3000,
                    style: {
                      fontSize: '15px', 
                      padding: '10px 20px', 
                      minWidth: '200px',
                    },
                });
                setAlertMessage({ type: 'success', text: 'Password has been reset successfully.Taking you back to Login' });
                navigate('/user-login');
            } else {
                toast.warning(ST.label5, {
                    position: 'top-center',
                    duration: 3000,
                    style: {
                      fontSize: '15px', 
                      padding: '10px 20px', 
                      minWidth: '200px',
                    },
                });
                setAlertMessage({ type: 'danger', text: 'Failed to reset password. Please try again Later.' });
            }
        } catch (error) {
            setAlertMessage({ type: 'danger', text: 'An error occurred while resetting your password.' });
        }
    };

    return (
        <div className="bg-light">
            <NavbarForgot />
            <Container className="d-flex justify-content-center align-items-center min-vh-100 py-5">
                <Row className="w-100">
                    <Col xs={12} md={8} lg={6} className="mx-auto">
                        <Card className="p-4 shadow-sm" style={{ minHeight: '400px' }}>
                            <Card.Body>
                                <h3 className="text-center mb-4">{ST.label}</h3>

                                {alertMessage && (
                                    <Alert variant={alertMessage.type} className="text-center">
                                        {alertMessage.text}
                                    </Alert>
                                )}
                                {errors.token && <Alert variant="danger">{errors.token}</Alert>}

                                {isTokenValid && (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formPassword" className="mb-3">
                                            <div className="position-relative">
                                                <MDBInput
                                                    label={ST.label1}
                                                    name="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                />
                                                <span
                                                    onClick={togglePasswordVisibility}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                                </span>
                                            </div>
                                            {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                                        </Form.Group>

                                        <Form.Group controlId="formPasswordConfirm" className="mb-3">
                                            <div className="position-relative">
                                                <MDBInput
                                                    label={ST.label2}
                                                    name="passwordconfirm"
                                                    type={showPassword1 ? 'text' : 'password'}
                                                    value={formData.passwordconfirm}
                                                    onChange={handleChange}
                                                />
                                                <span
                                                    onClick={togglePasswordVisibility1}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={showPassword1 ? faEye : faEyeSlash} />
                                                </span>
                                            </div>
                                            {errors.passwordconfirm && <p style={{ color: 'red' }}>{errors.passwordconfirm}</p>}
                                        </Form.Group>

                                        <Button
                                            style={{
                                                backgroundColor: '#f35b2a',
                                                fontWeight: 'bold',
                                                boxShadow: 'none',
                                            }}
                                            type="submit"
                                            className="w-100 fw-bold"
                                        >
                                            {ST.label3}
                                        </Button>
                                    </Form>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default ResetPassword;
