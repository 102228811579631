import React, { useState, useEffect } from 'react';

const DoctorProfileImage = ({ 
  imageUrl, 
  alt = "Doctor profile", 
  borderRadius = 999,
  mobileWidth = 80,
  tabletWidth = 80,
  desktopWidth = 80,
  gender = "MALE"
}) => {
  const getOptimizedUrl = (url, width) => {
    if (!url) return '';
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('width', width.toString());
      // urlObj.searchParams.set('quality', '90');
      urlObj.searchParams.set('format', 'webp');
      return urlObj.toString();
    } catch (e) {
      return url;
    }
  };

  const fallbackImage = gender === "FEMALE" ? "/doc-Female.png" : "/doc-Male.png";  
  console.log(`sarthak sir ${fallbackImage}`);

  if (!imageUrl) {
    return (
      <div 
        style={{ 
          borderRadius: '999px', 
          height: `${mobileWidth}px`, 
          width: `${mobileWidth}px` 
        }} 
        className="bg-gray-200 flex items-center justify-center"
      >
        <img src={fallbackImage}/>
      </div>
    );
  }

  return (
    <picture>
      <source 
        media="(min-width: 768px)" 
        srcSet={getOptimizedUrl(imageUrl, desktopWidth)}
      />
      <source 
        media="(min-width: 425px)" 
        srcSet={getOptimizedUrl(imageUrl, tabletWidth)}
      />
      <img
        src={getOptimizedUrl(imageUrl, mobileWidth)}
        alt={alt}
        style={{
          
          borderRadius: `${borderRadius}px`,
          height:'80px',
          width:'80px'
        }}
        className="object-cover w-full h-full"
        onError={(e) => {
          e.target.src = fallbackImage;
        }}
      />
    </picture>
  );
};

export default DoctorProfileImage;