import React from 'react';
import utils from "../../../utils";
import { useLanguage } from '../../homepage/components/LanguageContext';
import Creatable from 'react-select/creatable';

export default function SearchableSelect({
  name,
  arialabel,
  defaultValue,
  dataArAsObj,
  onChangeHandler,
  gender,
}) {
  const { language } = useLanguage();

  // Convert data into the format required by Creatable
  const getOptions = () => {
    if (dataArAsObj && !Array.isArray(dataArAsObj)) {
      return Object.keys(dataArAsObj).map((key) => ({
        value: key,
        label: dataArAsObj[key],
      }));
    }

    if (dataArAsObj && Array.isArray(dataArAsObj)) {
      return dataArAsObj.map((item) => ({
        value: item.id,
        label: item.specialityId
          ? utils.getLanguageGenderString(
              item.name,
              item.nameMaleDe,
              item.nameFemaleDe,
              language,
              gender
            )
          : utils.getLanguageString(language,item.name, item.nameDe),
      }));
    }

    return [];
  };


  const options = getOptions();

  const selectedOption = options.find((opt) => opt.value === defaultValue) || null;

  return (
    <Creatable
      name={name}
      aria-label={arialabel}
    //   value={selectedOption}
      options={options}
      onChange={(selected) => onChangeHandler({ target: { name, value: selected?.value } })}
      placeholder={defaultValue || 'Select or create an option'}
    />
  );
}
