import React, { useState } from 'react'
import NavbarContact from './NavbarContact';
import Footer from './Footer';
import { useLanguage } from './LanguageContext';
import { useAuthUser } from 'react-auth-kit';
import { useOnlineStatus } from '../../../common_components/hooks/OnlineStatus';
import OfflineStatus from '../../../common_components/Offline/OfflineStatus';

// const ContactUs = () => {
//   const [formData, setFormData] = useState({
//     fullName: '',
//     preferredDoctorId:'',
//     preferredDoctorCenterId:'',
//     preferredDoctorSpeciality:'',
//     preferredClinicHospital:'',
//     preferredTimeslot: '',
//     preferredAvoidDays: '',
//     isAptUrgent: '',
//     insuranceType: '',
//     insuranceNumber: '',
//     specialMention: '',
//     phone: '',
//     email: '',
//     terms_conditions: false,
//     info:{
//       message:''
//     },
//     type:'Contact Us'
//   });
//   const { language,langStrings } = useLanguage();
//   const ST = langStrings.CONTACTUS;
//   const authUser = useAuthUser();
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     if (name === 'message') {
//       setFormData({
//         ...formData,
//         info: {
//           ...formData.info,
//           message: value,
//         }
//       });
//     } else {
//       setFormData({
//         ...formData,
//         [name]: value,
//       });
//     }
//   };

//   const handleContact = async (e)=>{
//     e.preventDefault();
//     console.log('came into contact');
//     const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
//     const token = authUser()?.authToken ||  ''
//     const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+'/lead-capture', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'X-LOCALE': `${LanguageLocale}`,
//         'X-AUTHTOKEN': token
//       },
//       body: JSON.stringify(formData),
//     });

//     if (response.ok) {
//       console.log('Form submitted successfully');
//     } else {
//       console.log('Failed to submit form');
//     }
//   }




//   return (
//     <div>
//     <NavbarContact />
//     <div class="custom-containera">
//     <div class="custom-form">
//       <div class="custom-contact-info">
//         <h3 class="custom-title">{ST.label}</h3>
//         <p class="custom-text">
//           {ST.label1}
//         </p>
//         <div class="custom-info">
//           <div class="custom-information">
//             <i class="fas fa-map-marker-alt"></i> 
//             <div className='text-div'>Name</div>
//           </div>
//           <div class="custom-information">
//             <i class="fas fa-envelope"></i> 
//             <div className='text-div'>Email</div>
//           </div>
//           <div class="custom-information">
//             <i class="fas fa-phone"></i> 
//             <div className='text-div'>Phone</div>
//           </div>
//         </div>

//         <div class="custom-social-media">
//           <p>{ST.label2}</p>
//           <div class="custom-social-icons">
//             <a href="https://www.facebook.com/people/Arztme/61568272712289/?sk=about">
//               <i class="fab fa-facebook-f"></i>
//             </a>
//             <a href="https://x.com/Arztme">
//               <i class="fab fa-twitter"></i>
//             </a>
//             <a href="https://www.instagram.com/arztme/profilecard/?igsh=azVleGhzeGpyanBr">
//               <i class="fab fa-instagram"></i>
//             </a>
//             <a href="https://www.linkedin.com/company/arztme/?viewAsMember=true">
//               <i class="fab fa-linkedin-in"></i>
//             </a>
//           </div>
//         </div>
//       </div>

//     <div class="custom-contact-form">
//       <form style={{padding : "2.3rem 2.2rem"}} onSubmit={handleContact} autocomplete="off">
//         <h3 class="custom-title">{ST.label3}</h3>
//         <div class="custom-input-container">
//           <input type="text" name="fullName" value={formData.fullName} class="custom-input" placeholder='Name' onChange={handleInputChange} />
//         </div>
//         <div class="custom-input-container">
//           <input type="email" name="email" value={formData.email} class="custom-input" placeholder='Email' onChange={handleInputChange}/> 
//         </div>
//         <div class="custom-input-container">
//           <input type="tel" name="phone" value={formData.phone} class="custom-input" placeholder={ST.label6} onChange={handleInputChange}/>
         
//         </div>
//         <div class="custom-input-container custom-textarea">
//           <textarea name="message" value={formData.info.message} class="custom-input" placeholder={ST.label7} onChange={handleInputChange}></textarea>
//         </div>
//         <input type="submit" class="custom-btn" />
//       </form>
//     </div>
//   </div>
// </div> 

// </div>
//   )
// }

// export default ContactUs;


const ContactUs = () => {
  const { langStrings } = useLanguage();
  const ST = langStrings.CONTACTUS;
  const isOnline = useOnlineStatus();
  if(!isOnline) {
    return <OfflineStatus />
  }
  return (
    <>
      <NavbarContact />
      <div style={styles.page}>
        <div style={styles.container}>
          <h1 style={styles.title}>{ST.label}</h1>
          <p style={styles.subtitle}>{ST.label1}</p>

          <div style={styles.contactInfo}>
            <div style={styles.infoItem}>
              <i className="fas fa-map-marker-alt" style={styles.icon}></i>
              <h3 style={styles.infoTitle}>Address</h3>
              <p style={styles.infoText}>Address will come here</p>
            </div>

            <div style={styles.infoItem}>
              <i className="fas fa-phone" style={styles.icon}></i>
              <h3 style={styles.infoTitle}>Phone</h3>
              <p style={styles.infoText}>phone will come here</p>
            </div>

            <div style={styles.infoItem}>
              <i className="fas fa-envelope" style={styles.icon}></i>
              <h3 style={styles.infoTitle}>Email</h3>
              <p style={styles.infoText}>Email will come here</p>
            </div>
          </div>

          <div style={styles.socialLinks}>
            <a href="https://www.facebook.com/people/Arztme/61568272712289/?sk=about" style={styles.socialLink}>
              <i className="fab fa-facebook-f" style={styles.socialIcon}></i>
            </a>
            <a href="https://x.com/Arztme" style={styles.socialLink}>
              <i className="fab fa-twitter" style={styles.socialIcon}></i>
            </a>
            <a href="https://www.linkedin.com/company/arztme/?viewAsMember=true" style={styles.socialLink}>
              <i className="fab fa-linkedin-in" style={styles.socialIcon}></i>
            </a>
            <a href="https://www.instagram.com/arztme/profilecard/?igsh=azVleGhzeGpyanBr" style={styles.socialLink}>
              <i className="fab fa-instagram" style={styles.socialIcon}></i>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const styles = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #f35b2a 0%, #ff8c6b 100%)',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  container: {
    textAlign: 'center',
    maxWidth: '800px',
    width: '100%',
    backgroundColor: '#ffffff',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontSize: '2.5rem',
    color: '#f35b2a',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '1.2rem',
    color: '#666',
    marginBottom: '40px',
  },
  contactInfo: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginBottom: '40px',
  },
  infoItem: {
    flex: '1 1 200px',
    margin: '10px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  icon: {
    fontSize: '2rem',
    color: '#f35b2a',
    marginBottom: '10px',
  },
  infoTitle: {
    fontSize: '1.25rem',
    color: '#333',
    marginBottom: '10px',
  },
  infoText: {
    fontSize: '1rem',
    color: '#666',
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  socialLink: {
    textDecoration: 'none',
    color: '#f35b2a',
    transition: 'color 0.3s ease',
  },
  socialIcon: {
    fontSize: '1.5rem',
  },
  '@media (max-width: 768px)': {
    contactInfo: {
      flexDirection: 'column',
    },
    infoItem: {
      flex: '1 1 auto',
    },
  },
};

export default ContactUs;