import { useState, useEffect } from 'react';
import { MultiSelect } from "react-multi-select-component";
import './MultiSelectCompo2.css';
import { useLanguage } from '../../homepage/components/LanguageContext';
import utils from "../../../utils"
var _ = require('lodash');

export default function MultiSelectCompo2({
    id, 
    name, 
    label, 
    defaultValue, 
    dataAr,
    setFieldState,
    onChangeHandler,
    gender,
    disabled
}) {
  const { language } = useLanguage();
  const [transformedAr, setTransformedAr] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    let ar = [];
    dataAr.forEach(function (each) {
        let newObj = {}
        if(each.specialityId){
          console.log("gender came", gender)
          newObj = {
            label: utils.getLanguageGenderString(each.name,each.nameMaleDe,each.nameFemaleDe,language,gender),      
            value: each.id,        
            specialists: each.specialists || []
          }
        }else{
          newObj = {
            label: utils.getLanguageString(language,each.name,each.nameDe),      
            value: each.id,        
            specialists: each.specialists || []
        };
        }
        ar.push(newObj);
    });

    console.log("Transformed array is ", ar);
    setTransformedAr(ar);
}, [dataAr]);



  // useEffect(() => {
  //   if (dataAr) {
  //     const transformData = (items, level = 0) => {
  //       let transformed = [];
  //       items.forEach(item => {
  //         transformed.push({
  //           label: `${'--'.repeat(level)} ${item.name}`,
  //           value: item.id,
  //           originalData: item
  //         });
  //         if (item.specialities) {
  //           transformed = transformed.concat(transformData(item.specialities, level + 1));
  //         }
  //         if (item.specialists) {
  //           transformed = transformed.concat(transformData(item.specialists, level + 2));
  //         }
  //       });
  //       return transformed;
  //     };
  //     const transformed = transformData(dataAr);
  //     setTransformedAr(transformed);
  //   }
  // }, [dataAr]);
  


function getFieldDataForFacetsInForm(ar){
    let res = [];
    ar.forEach(function(each){
        res.push(each.value);
    })
    return res;
}
function changeHandler(e, name){
    console.log(`event changes was`, e);

    const selectedIds = e.map(selected => selected.value);


    setSelectedValues(e);
    setFieldState(e);
    if (onChangeHandler) {
      onChangeHandler(e); 
  }
}
  return (
      <MultiSelect
        options={transformedAr}
        value={selectedValues}
        labelledBy={label}
        onChange={(e) => changeHandler(e, name)}
        hasSelectAll={false}
        disabled={disabled} 
        overrideStrings={{
          selectSomeItems: label,
        }}
      />
  );
}
