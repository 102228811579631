import React from 'react';

const VerticalMapSkeleton = () => {
  return (
    <div style={{
      width: '30%',
      margin: '0 20px',
      padding: '0 40px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      animation: 'pulse 1.5s infinite'
    }}>
      <div style={{
        width: '100%',
        flex: '1 1 auto',
        backgroundColor: '#e0e0e0',
        borderRadius: '12px'
      }}></div>
      <div className="skeleton-line" style={{
        height: '30px',
        backgroundColor: '#e0e0e0',
        width: '80%',
        borderRadius: '8px',
        marginTop: '20px'
      }}></div>
      <div className="skeleton-line" style={{
        height: '20px',
        backgroundColor: '#e0e0e0',
        width: '60%',
        borderRadius: '6px',
        marginTop: '10px'
      }}></div>
    </div>
  );
};

export default VerticalMapSkeleton;