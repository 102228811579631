import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import emailjs from 'emailjs-com';
import DocProfileView from './DocProfileView';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import utils from './../../../utils.js';
import { useLanguage } from '../../homepage/components/LanguageContext.js';
import ErrorFallbackPage from '../../../common_components/Fallback/Fallback.jsx';
import { toast } from 'sonner';

const DocProfile = ({User_Id,dayWiseSlots1,dateWiseSlots,error: parentError}) => {
  const {id} = useParams();
  const [doc,setDoc] = useState({
    "id": "",
    "userId": "",
    "gender": "",
    "title": "",
    "firstname": "",
    "lastname": "",
    "profileName": "",
    "urlPath": "",
    "completeUrlPath": "",
    "profileImage": "",
    "prefixedProfileImage": "",
    "rating": "",
    "ratingCount": "",
    "rating1Count": "",
    "rating2Count": "",
    "rating3Count": "",
    "rating4Count": "",
    "rating5Count": "",
    "centers": [{
        "id": "",
        "doctorId": "",
        "practiceSize": "",
        "countryId": "",
        "countryStr": "",
        "countryStrDe": "",
        "stateId": "",
        "stateStr": "",
        "stateStrDe": "",
        "cityId": "",
        "cityStr": "",
        "cityStrDe": "",
        "address": "",
        "street": "",
        "area": "",
        "pincode": "",
        "lat": "",
        "lng": "",
        "website": "",
        "mail": "",
        "isdCode": "",
        "phone": "",
        "status": "",
        "createdAt": "",
        "updatedAt": ""
    }],
    "profession": "",
    "professionList": [],
    "professionListDe": [],
    "speciality": "",
    "specialityList": [],
    "specialityListDe": [],
    "specialist": "",
    "specialistList": [],
    "specialistListMaleDe": [],
    "specialistListFemaleDe": [],
    "languagesSpoken": "",
    "languagesSpokenList": [],
    "languagesSpokenListDe": [],
    "info": {
        "1_a_n_p": "",
        "5_a_i": "",
        "2_a_w_t": ""
    },
    "infoParsed": {
        "accepting_new_patients": "",
        "accepting_new_patientsDe": "",
        "accepts_insurance": "",
        "accepts_insuranceDe": "",
        "average_waiting_time_for_appointment": ""
    },
    "adminInfo": {},
    "adminInfoParsed": {},
    "createdAt": "",
    "updatedAt": "",
    "status": "",
    "ffStatus": "",
    "aptSlotsApi": "",
    "placeIdGoogle": "",
    "ratingGoogle": "",
    "ratingCountGoogle": "",
    "bgImage": "",
    "prefixedBgImage": "",
    "prefixedBgImageList": [],
    "insuranceCompany": "",
    "insuranceCompanyList": [],
    "insuranceCompanyGroup": "",
    "insuranceCompanyGroupList": [],
    "graduationUniversity": "",
    "education": "",
    "networkDoctor": "",
    "about": "",
    "aboutDe": ""
});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  
  const images = [
    {
      url: 'https://www.shutterstock.com/image-photo/portrait-receptionist-clipboard-near-desk-260nw-1439157059.jpg',
      alt: 'Image 1',
    },
    {
      url: 'https://thumbs.dreamstime.com/b/doctors-helping-tablet-healthcare-team-clinic-website-update-review-medicine-staff-assistance-medical-support-treatment-327560105.jpg',
      alt: 'Image 2',
    },
    {
      url: 'https://c8.alamy.com/comp/2KFTE6C/panoramic-view-of-clinic-corridor-while-workflow-of-hospital-with-doctors-patients-and-medical-staff-day-hospital-2KFTE6C.jpg',
      alt: 'Image 3',
    },
  ];
  useEffect(() => {
    const fetchDoctorData = async () => {
      try {
        const url = window.location.href;
        const result = url.match(/doctor\/(.*)/)[1];
        console.log(`sarthak sir here is path ${result}`)
        const apiUrl = `${process.env.REACT_APP_JAVA_BASE_URL}/doctor?urlPath=${result}&enrich=true`;
        const response = await fetch(apiUrl);
       
        const data = await response.json();
        if(data.correctUrl){
          console.log(`sarthak bhai it came in  ${JSON.stringify(data)}`)
          navigate(`/doctor/${data.correctUrl}`);
          window.location.reload();
        }
        setDoc(data);
        setIsLoading(false);
        console.log(data);
      } catch (err) {
        console.log(err.message)
        setIsLoading(false);
      }
    };

    fetchDoctorData();
  },[id] );

  const { language, langStrings, toggleLanguage } = useLanguage();
  const [formData, setFormData] = useState({
    fullName: '',
    preferredDoctorId: '',
    preferredDoctorCenterId: '',
    preferredDoctorSpeciality: '',
    preferredClinicHospital: '',
    preferredTimeslot: '',
    preferredAvoidDays: '',
    isAptUrgent: '',
    insuranceType: '',
    insuranceNumber: '',
    specialMention: '',
    phone: '',
    email: '',
    terms_conditions: false,
    info: {}
  });
  const [ReviewFormData, setReviewFormData] = useState({
    userId: User_Id,
    doctorId: '',
    doctorCenterId: '',
    rating: '',
    ratingTitle: '',
    ratingBody: '',
  });
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleOpen1 = () => setShow1(true);
  const [reviews, setReviews] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [totalReviews, setTotalReviews] = useState(0);
  const location = useLocation();
  const user = doc;
  const docLocation = doc?.centers?.[0] ? utils.getDocLocAddressStr(doc.centers[0], language) : '';
  const docLocationMap = {
    lat: doc.centers?.[0]?.lat || 47.80164,
    lng: doc.centers?.[0]?.lng || 47.80164
  };
  const form = useRef();
  const isdoc = location.state?.isdoc;
  const UserId = User_Id;
  const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat","Sun"];
  const [errors, setErrors] = useState({});
  const dayWiseSlots = (dayWiseSlots1 || []);
  const [expandedCard, setExpandedCard] = useState(null);
  const [found,setFound] = useState(true);
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const MT = langStrings.BOOKINGMODAL;
  const rating = user?.rating || 0;
  const ratingCount = user?.ratingCount || 0;
  const rating1Count = user?.rating1Count || 0;
  const rating2Count = user?.rating2Count || 0 ;
  const rating3Count = user?.rating3Count || 0 ;
  const rating4Count = user?.rating4Count || 0;
  const rating5Count = user?.rating5Count || 0;

  useEffect(() => {
    if(user)
      findReview();
    if (pageNumber >= 0) { 
      fetchReviews(pageNumber);
    }
  }, [pageNumber,user])


  

  

  
  
  const validateForm = () => {
    let newErrors = {};
    if (!formData.fullName) newErrors.fullName = MT.label26;
    if (!formData.preferredTimeslot) newErrors.preferredTimeslot = MT.label27;
    if (!formData.preferredAvoidDays) newErrors.preferredAvoidDays = MT.label28;
    if (!formData.isAptUrgent) newErrors.isAptUrgent = MT.label29;
    if (!formData.insuranceType) newErrors.insuranceType = MT.label30;
    if (formData.insuranceType && !formData.insuranceNumber) newErrors.insuranceNumber = MT.label31;
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = MT.label32;
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = MT.label33;
    if (!formData.terms_conditions) newErrors.terms_conditions = MT.label34;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };

  const convertToTime = (minutes) => {
    if (minutes === null) return null;
    const hours = Math.floor(minutes / 60);
    let mins = minutes % 60;
    mins = mins < 10 ? '0' + mins : mins;
    return `${hours}:${mins}`;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    emailjs.sendForm('service_0omlcqq', 'template_vgy1gc7', form.current, 'TWYzr12J2tQNs-1Rq')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
      }, (error) => {
        console.error('Failed to send email:', error);
      });
  };

  const takeToEdit = () => {
    navigate(`/edit-doctor-profile/${doc?.id}`, { state: { doc,docLocation} });
  };

  const toggleCard = (cardIndex) => {
    setExpandedCard(expandedCard === cardIndex ? null : cardIndex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
    const token = authUser()?.authToken ||  ''
    const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+'/lead-capture', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-LOCALE': `${LanguageLocale}`,
        'X-AUTHTOKEN': token
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      console.log('Form submitted successfully');
    } else {
      console.log('Failed to submit form');
    }
  };
  
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setReviewFormData({
      ...ReviewFormData,
      [name]: value,
    });
  };

  const handleRatingChange1 = (e) => {
    setReviewFormData({
      ...ReviewFormData,
      rating: e.target.value,  
    });
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    try {
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken ||  ''
      console.log(`sarthak review api token: ${token}`);
      toast.info(MT.post, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+'/review', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN': token
        },
        body: JSON.stringify({
          userId: UserId,
          doctorId: user?.id,
          doctorCenterId: user?.centers[0]?.id || '0',
          rating: ReviewFormData.rating,
          ratingTitle: ReviewFormData.ratingTitle,
          ratingBody: ReviewFormData.ratingBody,  
        }),
      });
      setTimeout(() => {
      if (response.ok) {
        toast.success(MT.sucess, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
        // const result = await response.json();
        // console.log('Review submitted:', result);
        fetchReviews(pageNumber);
      } else {
        console.error('Failed to submit review');
        toast.error(MT.wrong, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
     
      }
    }, 500); 
    } catch (error) {
      console.error('Error:', error);
      toast.error(MT.wrong, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
    }
  };

  const fetchReviews = async (pageNum) => {
    const pageSize = 3;
    const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
    const token = authUser()?.authToken || '';
    
    try {
      const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+`/review?offset=${pageNum}&pageSize=${pageSize}&sortBy=&doctorCenterId=${user?.centers?.[0]?.id}`, {
        headers: {
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN': token
        },
      });
      const data = await response.json();
      console.log(data);
      
      if (data.content) {
        if (pageNum === 0) {
          setReviews(data.content);
        } else {
          setReviews((prevReviews) => {
            const newReviews = data.content.filter(
              (newReview) => !prevReviews.some((existingReview) => existingReview.id === newReview.id)
            );
            return [...prevReviews, ...newReviews];
          });
        }
        setIsLastPage(data.last);
        setTotalReviews(data.totalElements);
      }
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };
  

  const findReview = async()=>{
    try {
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken ||  ''

      const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+`/my-review-doc?userId=${UserId}&doctorCenterId=${user?.centers?.[0]?.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN': token
        },
      });
      if (response ) {
        const result = await response.json();
        console.log(
          'Review found',result
        )
        if(result == null){
          setFound(false);
        }
      } 
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  const loadMoreReviews = () => {
    if (!isLastPage) {
      console.log("Loading more reviews...");
      setPageNumber((prevPage) => prevPage + 1);
    }
  };
  
  const handlePreSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(e);
      handleClose1();
    }else{
      console.log('Please Fill all the Fields Correctly');
    }
  };
  

  const takeToLogin = ()=>{
    navigate('/user-login', { state: { from: location } });
  }

  return (
    <DocProfileView
      images={images}
      show={show}
      setShow={setShow}
      handleOpen={handleOpen}
      handleClose={handleClose}
      show1={show1}
      handleOpen1={handleOpen1}
      handleClose1={handleClose1}
      user={user}
      docLocation={docLocation}
      docLocationMap={docLocationMap}
      isdoc={isdoc}
      form={form}
      formData={formData}
      setFormData={setFormData}
      errors={errors}
      handleInputChange={handleInputChange}
      sendEmail={sendEmail}
      takeToEdit={takeToEdit}
      expandedCard={expandedCard}
      setExpandedCard={setExpandedCard}
      toggleCard={toggleCard}
      handlePreSubmit={handlePreSubmit}
      ReviewFormData={ReviewFormData}
      setReviewFormData={setReviewFormData}
      handleInputChange1={handleInputChange1}
      handleRatingChange1={handleRatingChange1}
      handleSubmit1={handleSubmit1}
      dayWiseSlots={dayWiseSlots}
      dayNames={dayNames}
      convertToTime={convertToTime}
      reviews={reviews}
      isLastPage={isLastPage}
      loadMoreReviews={loadMoreReviews}
      totalReviews={totalReviews}
      UserId={UserId}
      found={found}
      pageNumber={pageNumber}
      fetchReviews={fetchReviews}
      isAuthenticated={isAuthenticated}
      takeToLogin={takeToLogin}
      rating={rating}
      ratingCount={ratingCount}
      rating1Count={rating1Count}
      rating2Count={rating2Count}
      rating3Count={rating3Count}
      rating4Count={rating4Count}
      rating5Count={rating5Count}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default DocProfile;
