import React from 'react';

const Pulse = ({ style, ...props }) => (
  <div
    style={{
      position: 'relative',
      overflow: 'hidden',
      ...style,
    }}
    {...props}
  >
    <div
      style={{
        position: 'absolute',
        inset: 0,
        transform: 'translateX(-100%)',
        animation: 'shimmer 2s infinite',
        background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent)',
      }}
    />
  </div>
);

const SkeletonLoader = () => {
  return (
    <div style={{ width: '100%', maxWidth: '72rem', margin: '0 auto', backgroundColor: 'white' }}>
      {/* Hero Banner */}
      <div style={{ position: 'relative' }}>
        <Pulse
          style={{
            width: '100%',
            height: '18rem',
            background: 'linear-gradient(90deg, #e5e7eb, #d1d5db)',
            borderRadius: '0 0 0.5rem 0.5rem',
          }}
        />

        {/* Profile Section */}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '2rem',
            padding: '0 2rem',
            marginTop: '-6rem',
            marginBottom: '3rem',
            position: 'relative',
          }}
        >
          {/* Profile Image */}
          <Pulse
            style={{
              width: '12rem',
              height: '12rem',
              background: 'linear-gradient(135deg, #d1d5db, #9ca3af)',
              borderRadius: '1rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          />

          {/* Basic Info */}
          <div style={{ flex: 1, paddingTop: '6rem' }}>
            {/* Name */}
            <Pulse
              style={{
                height: '2.25rem',
                background: 'linear-gradient(90deg, #9ca3af, #d1d5db)',
                borderRadius: '0.5rem',
                width: '18rem',
                marginBottom: '0.75rem',
              }}
            />
            {/* Title */}
            <Pulse
              style={{
                height: '1.5rem',
                background: 'linear-gradient(90deg, #d1d5db, #e5e7eb)',
                borderRadius: '0.5rem',
                width: '6rem',
                marginBottom: '1.5rem',
              }}
            />

            {/* Contact Info */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Pulse
                style={{
                  height: '1.25rem',
                  background: 'linear-gradient(90deg, #d1d5db, #e5e7eb)',
                  borderRadius: '9999px',
                  width: '9rem',
                }}
              />
              <Pulse
                style={{
                  height: '1.25rem',
                  background: 'linear-gradient(90deg, #d1d5db, #e5e7eb)',
                  borderRadius: '9999px',
                  width: '16rem',
                }}
              />
              <Pulse
                style={{
                  height: '1.25rem',
                  background: 'linear-gradient(90deg, #d1d5db, #e5e7eb)',
                  borderRadius: '9999px',
                  width: '12rem',
                }}
              />
            </div>
          </div>

          {/* Rating & Button */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '1rem', paddingTop: '6rem' }}>
            <Pulse
              style={{
                height: '1.5rem',
                background: 'linear-gradient(90deg, #d1d5db, #e5e7eb)',
                borderRadius: '9999px',
                width: '6rem',
              }}
            />
            <Pulse
              style={{
                height: '2.75rem',
                background: 'linear-gradient(90deg, #fed7aa, #fdba74)',
                borderRadius: '9999px',
                width: '11rem',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
              }}
            />
          </div>
        </div>
      </div>

      {/* About Section */}
      <div style={{ padding: '0 2rem', marginBottom: '3rem' }}>
        <Pulse
          style={{
            height: '2rem',
            background: 'linear-gradient(90deg, #9ca3af, #d1d5db)',
            borderRadius: '0.5rem',
            width: '8rem',
            marginBottom: '1.5rem',
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
          <Pulse
            style={{
              height: '1rem',
              background: 'linear-gradient(90deg, #e5e7eb, #d1d5db)',
              borderRadius: '9999px',
              width: '100%',
            }}
          />
          <Pulse
            style={{
              height: '1rem',
              background: 'linear-gradient(90deg, #e5e7eb, #d1d5db)',
              borderRadius: '9999px',
              width: '83.333333%',
            }}
          />
          <Pulse
            style={{
              height: '1rem',
              background: 'linear-gradient(90deg, #e5e7eb, #d1d5db)',
              borderRadius: '9999px',
              width: '66.666667%',
            }}
          />
        </div>
      </div>

      {/* Info Cards */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '1.5rem',
          padding: '0 2rem',
          marginBottom: '3rem',
        }}
      >
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            style={{
              padding: '1.5rem',
              borderRadius: '1rem',
              background: 'linear-gradient(135deg, #f3f4f6, #e5e7eb)',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Pulse
              style={{
                height: '2rem',
                background: 'linear-gradient(90deg, #d1d5db, #e5e7eb)',
                borderRadius: '0.5rem',
                width: '8rem',
                marginBottom: '1.5rem',
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
              <Pulse
                style={{
                  height: '1rem',
                  background: 'linear-gradient(90deg, #d1d5db, #e5e7eb)',
                  borderRadius: '9999px',
                  width: '83.333333%',
                }}
              />
              <Pulse
                style={{
                  height: '1rem',
                  background: 'linear-gradient(90deg, #d1d5db, #e5e7eb)',
                  borderRadius: '9999px',
                  width: '66.666667%',
                }}
              />
            </div>
            {i === 2 && (
              <div style={{ display: 'flex', gap: '0.75rem', marginTop: '1.5rem' }}>
                {[...Array(3)].map((_, j) => (
                  <Pulse
                    key={j}
                    style={{
                      width: '5rem',
                      height: '4rem',
                      background: 'linear-gradient(135deg, #d1d5db, #e5e7eb)',
                      borderRadius: '0.5rem',
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;