import React from 'react';

const DoctorCardSkeleton = () => {
  return (
    <div className="skeleton-card" style={{
      display: 'flex',
      backgroundColor: '#f0f0f0',
      padding: '20px',
      marginBottom: '15px',
      borderRadius: '8px',
      animation: 'pulse 1.5s infinite'
    }}>
      <div className="skeleton-avatar" style={{
        width: '100px',
        height: '100px',
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        marginRight: '20px'
      }}></div>
      <div className="skeleton-content" style={{flex: 1}}>
        <div className="skeleton-line" style={{
          height: '20px',
          backgroundColor: '#e0e0e0',
          marginBottom: '10px',
          width: '60%'
        }}></div>
        <div className="skeleton-line" style={{
          height: '15px',
          backgroundColor: '#e0e0e0',
          marginBottom: '10px',
          width: '40%'
        }}></div>
        <div className="skeleton-line" style={{
          height: '15px',
          backgroundColor: '#e0e0e0',
          width: '50%'
        }}></div>
      </div>
    </div>
  );
};

export default DoctorCardSkeleton;