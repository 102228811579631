import React from 'react';

const ResponsiveDoctorImage = ({ 
  imageUrl, 
  alt = "Doctor profile",
  mobileWidth = 120,
  tabletWidth = 150,
  desktopWidth = 200,
  borderRadius = 0,
  gender = "MALE"
}) => {
  const getOptimizedUrl = (url, width) => {
    if (!url) return '';
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('width', width.toString());
      urlObj.searchParams.set('quality', '90');
      urlObj.searchParams.set('format', 'webp');
      return urlObj.toString();
    } catch (e) {
      return url;
    }
  };

  const fallbackImage = gender === "FEMALE" ? "/doc-Female.png" : "/doc-Male.png";  

  if (!imageUrl) {
    return (
      <div 
        style={{ 
          borderRadius: '999px', 
          height: `${mobileWidth}px`, 
          width: `${mobileWidth}px` 
        }} 
        className="object-cover w-full h-full"
      >
        <img src={fallbackImage}/>
      </div>
    );
  }

  return (
    <picture>
      <source 
        media="(min-width: 768px)" 
        srcSet={getOptimizedUrl(imageUrl, desktopWidth)}
      />
      <source 
        media="(min-width: 425px)" 
        srcSet={getOptimizedUrl(imageUrl, tabletWidth)}
      />
      <img
        src={getOptimizedUrl(imageUrl, mobileWidth)}
        alt={alt}
        style={{
          borderRadius: `${borderRadius}px`,
        }}
        className="object-cover w-full h-full"
        onError={(e) => {
          e.target.src = fallbackImage;
        }}
      />
    </picture>
  );
};

export default ResponsiveDoctorImage;