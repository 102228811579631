import React from 'react';
import { GoDotFill } from "react-icons/go";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Footer from './Footer.jsx';
import { useLanguage } from './LanguageContext.js';
import NavBarDocEdit from '../../docsearch/components/NavBarDocEdit.jsx';
import { useOnlineStatus } from '../../../common_components/hooks/OnlineStatus.js';
import OfflineStatus from '../../../common_components/Offline/OfflineStatus.jsx';

const AreYouDocView = ({
    ST,
    gotohome,
    takeToDocRegister,
}) => {
  const { language } = useLanguage();
  const isOnline = useOnlineStatus();
  if(!isOnline) {
    return <OfflineStatus />
  }
  return (
    <>
      <NavBarDocEdit />
      <div className='are-you-doc-container' style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',  paddingTop: '80px' }}>
        <div className="container" style={{ padding: '40px 20px', maxWidth: '1200px', margin: '0 auto' }}>
          <div className="row align-items-center">
            {/* Left Column - Image */}
            <div className="col-md-6 col-lg-6 position-relative text-center">
              {/* <IoArrowBackCircleOutline
                onClick={gotohome}
                style={{ 
                  fontSize: '40px', 
                  position: 'absolute', 
                  left: '30px', 
                  top: '0px', 
                  cursor: 'pointer', 
                  color: 'white', 
                  zIndex: 1,
                  
                }}
              /> */}
              <img
                className='img-fluid'
                src="/steth.png"
                alt="Stethoscope"
                style={{ maxWidth: '100%', height: 'auto', borderRadius: '15px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' }}
              />
            </div>

            
            <div className="col-md-6 col-lg-6" style={{ padding: '20px' }}>
              <div style={{ marginBottom: '40px' }}>
                <h1 style={{ fontSize: '2.5rem', fontWeight: '700', color: 'white', marginBottom: '20px', lineHeight: '1.2' }}>
                  {ST.HEADERS.label}
                </h1>
                <p style={{ fontSize: '1.1rem', color: 'white', marginBottom: '30px', lineHeight: '1.6' }}>
                  {ST.HEADERS.label1}
                </p>
              </div>

              <div style={{ marginBottom: '40px' }}>
                {[ST.HEADERS.label2, ST.HEADERS.label3, ST.HEADERS.label4, ST.HEADERS.label5].map((label, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                    <GoDotFill size={20} style={{ color: 'white', flexShrink: 0, marginTop: '2px' }} /> {/* Adjusted marginTop */}
                    <span style={{ fontSize: '1rem', color: 'white', marginLeft: '12px', lineHeight: '1.5' }}>
                      {label}
                    </span>
                  </div>
                ))}
              </div>

          
              <div style={{ textAlign: 'left' }}>
                <button
                  onClick={takeToDocRegister}
                  style={{
                    padding: '14px 32px',
                    backgroundColor: '#f35b2a',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    fontSize: '1rem',
                    fontWeight: '600',
                    cursor: 'pointer',
                    
                    boxShadow: '0 4px 6px rgba(0, 123, 255, 0.2)',
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = '#f35b2a';
                    e.target.style.transform = 'translateY(-2px)';
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = '#f35b2a';
                    e.target.style.transform = 'translateY(0)';
                  }}
                >
                  {ST.HEADERS.label6}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default AreYouDocView;