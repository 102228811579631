import React, { useEffect, useState } from 'react';
import Footer from './Footer.jsx';
import sitelogo from "./../../../../src/images/Arztme_Logo_Slogan.webp";
import { RxCross2 } from "react-icons/rx";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext.js';
import { useAuthUser, useIsAuthenticated, useSignOut } from 'react-auth-kit';

const HamBurg = () => {
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [correctUrl,setCorrectUrl] =  useState('');
  const Home = ()=>{
    navigate('/')
  }
  const JaoDocPe = ()=>{
    navigate('/new-are-you-a-doctor')
  }
  const gotoSignUp = ()=>{
    navigate('/new-user-register')
  }
  const gotoLogin = ()=>{
    navigate('/user-login')
  }
  const gotoLogout = ()=>{
    signOut();
    navigate('/home-sign-up');
  }


  const id = authUser()?.doctorId;
  const getImageUrl = async (id) => {
    try{
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken ||  ''
      const url = process.env.REACT_APP_JAVA_BASE_URL + `/doctor/id?doctorId=${id}&enrich=true`;
      const call = await fetch(url,{headers:{
        'X-LOCALE': `${LanguageLocale}`,
        'X-AUTHTOKEN': token
      }})
      
      if(call.ok){
        const response = await call.json();
        console.log(`sarthak bhai response Dekh lo`,response.prefixedProfileImage);
        
        setCorrectUrl(response.completeUrlPath);
      }else{
        console.log('nahi aaya response bhai log');
      }
    }catch(err){
      console.log('error aaya',err);
    }
  }
  useEffect(()=>{
    if(id){
      getImageUrl(id);
    }
    
  },[id])
    
  
  const gotoProfile = ()=>{
    const doctorId = authUser()?.doctorId;
    console.log(doctorId);
    navigate('/doctor/'+correctUrl);
  }
  console.log(isAuthenticated())
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.HOMEPAGE;

  return (
    <div className='bg-light position-relative'>
      <div className='container'>
        
        <div className='d-flex justify-content-center align-items-start py-3'>
          <img  style={{maxWidth : '240px'}} src={sitelogo} alt="Logo" />
        </div>

       
        <div className='position-absolute top-0 end-0 m-2'>
          <RxCross2 onClick={Home} style={{color: 'black'}} size={30} />
        </div>

      
        <div className='d-flex flex-column align-items-center text-center py-5'>
          <p className='text-dark h4 mb-4'>{ST.HAMBURG.label} Arztme</p>
          
    
          {
            !isAuthenticated() ? 
            <div className='d-flex flex-column w-100' style={{ maxWidth: '300px' }}>
              <Button style={{backgroundColor:'#f35b2a'}} onClick={gotoSignUp} className='btn btn-warning btn-lg mb-3 w-100'>Sign Up</Button>
              <Button style={{backgroundColor:'#f35b2a'}} onClick={gotoLogin} className='btn btn-warning btn-lg mb-4 w-100'>Log In</Button>
            </div>:
            <div className='d-flex flex-column w-100' style={{ maxWidth: '300px' }}>
              {
                authUser().isDoctor? 
                <Button style={{backgroundColor:'#f35b2a'}} onClick={gotoProfile} className='btn btn-warning btn-lg mb-3 w-100'>Manage Profile</Button>
                :<></>
              }
              
              <Button style={{backgroundColor:'#f35b2a'}} onClick={gotoLogout} className='btn btn-warning btn-lg mb-4 w-100'>Log Out</Button>
            </div>
          }
          

          <p onClick={JaoDocPe} style={{fontWeight:'bold',cursor:'pointer'}} className='text-dark'>
            {ST.HAMBURG.label1}{ST.HAMBURG.label2}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HamBurg;
