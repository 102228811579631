import React, { useState } from 'react';
import './ImageScroll.css';

const ImageScroll = ({ user }) => {
  const [loadedImages, setLoadedImages] = useState({});
  const Images = user?.prefixedBgImageList || [];
  const limitedImages = Images.slice(0, 3);

  const handleImageLoad = (id) => {
    setLoadedImages((prev) => ({ ...prev, [id]: true }));
  };

  return (
    <div className="image-scroll-container">
      {Images.length > 0 ? (
        <div className="image-scroll-wrapper">
          {limitedImages.map((image, index) => (
            <div key={image.id} className="image-container">
              <img
                src={image.mediaLinkAbs}
                alt={`Image ${index + 1}`}
                className={`scroll-image placeholder ${
                  loadedImages[image.id] ? 'hidden' : ''
                }`}
              />
              <img
                src={image.mediaLinkAbs}
                alt={`Image ${index + 1}`}
                className={`scroll-image full-image ${
                  loadedImages[image.id] ? 'visible' : 'hidden'
                }`}
                onLoad={() => handleImageLoad(image.id)}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="image-scroll-wrapper">
          <img src="/images.png" alt="Image 1" className="scroll-image" />
          <img src="/images 1.png" alt="Image 2" className="scroll-image" />
          <img src="/images 2.png" alt="Image 3" className="scroll-image" />
        </div>
      )}
    </div>
  );
};

export default ImageScroll;