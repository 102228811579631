import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps';
import PoiMarker from './PoiMarker';
import './css/RenderMap.css';

const RenderMap = forwardRef((props, ref) => {
  const API_KEY = process.env.REACT_APP_GEOCODING_API_KEY;

  const MapComponent = ({ hoveredDoctorId, srpData, selectedDoc }) => {
    const map = useMap();

    const smoothPanTo = (location, zoom = null) => {
      if (!map) return;

      // Pan with smooth animation
      map.panTo(location);

      // Add smooth zoom if specified
      if (zoom !== null) {
        map.animateCamera({
          center: location,
          zoom: zoom,
          duration: 1000 // Duration in milliseconds
        });
      }
    };

    useEffect(() => {
      if (hoveredDoctorId && srpData?.doctorList && map) {
        const hoveredDoctor = srpData.doctorList.find(
          doctor => doctor.id === hoveredDoctorId
        );
        
        if (hoveredDoctor?.centers?.[0]) {
          const location = {
            lat: Number(hoveredDoctor.centers[0].lat),
            lng: Number(hoveredDoctor.centers[0].lng)
          };
          
          smoothPanTo(location);
        }
      }
    }, [hoveredDoctorId, srpData, map]);

    return (
      <PoiMarker 
        srpData={srpData} 
        selectedDoc={selectedDoc} 
        hoveredDoctorId={hoveredDoctorId}
      />
    );
  };

  useImperativeHandle(ref, () => ({
    centerOnLocation: (location) => {
      const mapInstance = document.querySelector('[aria-label="Map"]')?.__gm?.map;
      if (mapInstance) {
        // Use smooth animation for both pan and zoom
        mapInstance.animateCamera({
          center: location,
          zoom: 13,
          duration: 1000, // Duration in milliseconds
          easing: 'easeInOutCubic' // Smooth easing function
        });
      }
    }
  }));

  const defaultCenter = { lat: 28.6139, lng: 77.2090 }; 
  const defaultZoom = 11;

  return (
    <APIProvider apiKey={API_KEY}>
      <Map
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        gestureHandling="cooperative"
        disableDefaultUI={true}
        mapId='DEMO_MAP_ID'
        options={{
          // Add smooth zoom options
          zoomControl: true,
          scrollwheel: true,
          animatedZoom: true,
          // Add custom styles for smoother animations
          styles: [
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                { transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)" },
                { transitionDuration: "0.5s" }
              ]
            }
          ]
        }}
      >
        <MapComponent 
          hoveredDoctorId={props.hoveredDoctorId}
          srpData={props.srpData}
          selectedDoc={props.selectedDoc}
        />
      </Map>
    </APIProvider>
  );
});

export default RenderMap;