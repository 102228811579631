import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DocProfile from './DocProfile';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useLanguage } from '../../homepage/components/LanguageContext';
import ErrorFallbackPage from '../../../common_components/Fallback/Fallback';

const DocProfileParent = () => {
  const [isLoading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [error, setError] = useState(null);
  const [dayWiseSlots1,setdayWiseSlots] = useState({});
  const [dateWiseSlots,setdateWiseSlots] = useState({});
  const [User_Id, setUser_Id] = useState(-1);
  const {id} = useParams();
  const {language} = useLanguage();
  const navigate  = useNavigate();
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();

  const [doc, setDoc] = useState({
    "id": "",
    "userId": "",
    "gender": "",
    "title": "",
    "firstname": "",
    "lastname": "",
    "profileName": "",
    "urlPath": "",
    "completeUrlPath": "",
    "profileImage": "",
    "prefixedProfileImage": "",
    "rating": "",
    "ratingCount": "",
    "rating1Count": "",
    "rating2Count": "",
    "rating3Count": "",
    "rating4Count": "",
    "rating5Count": "",
    "centers": [{
        "id": "",
        "doctorId": "",
        "practiceSize": "",
        "countryId": "",
        "countryStr": "",
        "countryStrDe": "",
        "stateId": "",
        "stateStr": "",
        "stateStrDe": "",
        "cityId": "",
        "cityStr": "",
        "cityStrDe": "",
        "address": "",
        "street": "",
        "area": "",
        "pincode": "",
        "lat": "",
        "lng": "",
        "website": "",
        "mail": "",
        "isdCode": "",
        "phone": "",
        "status": "",
        "createdAt": "",
        "updatedAt": ""
    }],
    "profession": "",
    "professionList": [],
    "professionListDe": [],
    "speciality": "",
    "specialityList": [],
    "specialityListDe": [],
    "specialist": "",
    "specialistList": [],
    "specialistListMaleDe": [],
    "specialistListFemaleDe": [],
    "languagesSpoken": "",
    "languagesSpokenList": [],
    "languagesSpokenListDe": [],
    "info": {
        "1_a_n_p": "",
        "5_a_i": "",
        "2_a_w_t": ""
    },
    "infoParsed": {
        "accepting_new_patients": "",
        "accepting_new_patientsDe": "",
        "accepts_insurance": "",
        "accepts_insuranceDe": "",
        "average_waiting_time_for_appointment": ""
    },
    "adminInfo": {},
    "adminInfoParsed": {},
    "createdAt": "",
    "updatedAt": "",
    "status": "",
    "ffStatus": "",
    "aptSlotsApi": "",
    "placeIdGoogle": "",
    "ratingGoogle": "",
    "ratingCountGoogle": "",
    "bgImage": "",
    "prefixedBgImage": "",
    "prefixedBgImageList": [],
    "insuranceCompany": "",
    "insuranceCompanyList": [],
    "insuranceCompanyGroup": "",
    "insuranceCompanyGroupList": [],
    "graduationUniversity": "",
    "education": "",
    "networkDoctor": "",
    "about": "",
    "aboutDe": ""
});

  const findinfo = async () => {
    try {
      const url = window.location.href;
      const result = url.match(/doctor\/(.*)/)[1];
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken ||  '';
      const res = await fetch(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor?urlPath=${result}&enrich=true`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          }
        }
      );
      if (res.ok) {
        const result = await res.json();
        if(!result?.completeUrlPath){
          navigate(`/doctor/${result.correctUrl}`);
          window.location.reload();
        }
        setDoc(result); 
        setLoading(false); 
      } else {
        setError('Failed to get info');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); 
    }
  };

  
  const getUserId = ()=>{
    if(isAuthenticated() ){
      setUser_Id(authUser().userDto.id);
    }
  }

  const startTime = () => {
    const dateObj = new Date();
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const days = 7;
  
  const findSlots = async ()=>{
    try {
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken ||  ''
      const res = await fetch(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor-center-slot/apt-slots/${doc.centers[0].id}?start=${startTime()}&days=${days}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          }
        }
      );
      if (res.ok) {
        const result = await res.json();
        console.log('Result fetched:', result);
        setdayWiseSlots(result.dayWiseSlots);
        setdateWiseSlots(result.dateWiseSlots);
        setLoading1(false); 
      } else {
        console.error('Failed to get slots');
      }
    } catch (error) {
      console.error('Error:', error);
    }finally {
      setLoading1(false); 
    }
  }

  useEffect(() => {
    getUserId();
  }, [isAuthenticated,authUser])

  useEffect(() => {
    findinfo();
  },[id]);

  useEffect(() => {
    if (doc && doc.centers && doc.centers[0]?.id) {
      findSlots();
    }
  }, [doc]);

  if (error) {
    return <ErrorFallbackPage />; 
  }
  console.log(`data is as follows`);
  console.log(doc);
  console.log(User_Id)
  console.log(dayWiseSlots1);
  console.log(dateWiseSlots);
  console.log(error)
  return (
    <DocProfile doc={doc} User_Id={User_Id} dayWiseSlots1={dayWiseSlots1} dateWiseSlots={dateWiseSlots} error={error} isLoading={isLoading} />
  );
};

export default DocProfileParent;